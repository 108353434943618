import React from 'react';
import styles from './index.module.scss';
import IcnQuestion from '../../images/icn-question.svg';
import {Link} from "react-router-dom";
import {logEvent} from "../../services/AnalyticsService";
import PrimaryButton from "../PrimaryButton";

interface LocalState {
}

interface Props {
  readonly title: string;
  readonly page: string;
  readonly showCheckout: boolean;
}

class BreakdownModule extends React.Component<Props, LocalState> {
  state = {};

  guaranteeInfo = () => {
    logEvent('30 Day Guarantee', {'Source': this.props.page});
  };

  render() {
    return (
      <div className={styles.priceModule}>
        <h2>{this.props.title}</h2>

        <div>
          <div className={styles.breakDownItem}>
            <div>WPC Entry</div>
            <div>included</div>
          </div>
          <div className={styles.breakDownItem}>
            <div>Putter attachment</div>
            <div>included</div>
          </div>
          <div className={styles.breakDownItem}>
            <div>Software + Swing analyzer</div>
            <div>included</div>
          </div>
          <div className={styles.breakDownItem}>
            <div>Total</div>
            <div>$499.00</div>
          </div>
        </div>

        <div className={styles.guarantee}>
          <span>30 day guarantee</span>
          <button onClick={this.guaranteeInfo}><img src={IcnQuestion} alt="Info"/></button>
        </div>

        {
          this.props.showCheckout &&
          <div className={styles.orderNow}>
              <PrimaryButton to={'/checkout'}>Order Now</PrimaryButton>
          </div>
        }
      </div>
    );
  }
}

export default BreakdownModule;
