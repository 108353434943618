import {setUserId} from "./AnalyticsService";

const STORAGE_KEY = 'authUserEmail';

export const startSession = (email: string, userId: string) => {
  localStorage.setItem(STORAGE_KEY, email);
  setUserId(userId)
};

export const endSession = () => {
  localStorage.removeItem(STORAGE_KEY);
  setUserId()
};

export const sessionKey = (): string => {
  return localStorage[STORAGE_KEY];
};