import firebase from '../../firebase';
import React from 'react';
import styles from './index.module.scss';
import {IAppState} from '../../store/Store';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import LoginForm from "../../components/LoginForm";
import LogoutAdmin from "../../components/LogoutAdmin";
import {addOneUser, findUserByEmail, moveToWhiteList, clearFoundUser, clearUserAddError} from "../../actions/admin";
import AdminActions from "../../components/AdminActions";
import {User} from "../../reducers/user";

const mapStateToProps = (state: IAppState) => ({
  currentUser: state.admin.user,
  addingUserFailed: state.admin.addingUserFailed
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addOneUser,
      findUserByEmail,
      moveToWhiteList,
      clearFoundUser,
      clearUserAddError
    },
    dispatch
  );

interface Props {
  readonly history: History;
  readonly currentUser?: User;
  readonly addingUserFailed: string;
  readonly clearFoundUser: () => void;
  readonly clearUserAddError: () => void;
  readonly findUserByEmail: (email: string) => void;
  readonly addOneUser: (email: string, created: () => void) => void;
  readonly moveToWhiteList: (user: User, added: () => void) => void;
}


interface LocalState {
  readonly authenticated: boolean;
}

class AdminView extends React.Component<Props, LocalState> {
  state = {
    authenticated: false,
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((authenticated) => {
      authenticated
        ? this.setState(() => ({
          authenticated: true,
        }))
        : this.setState(() => ({
          authenticated: false,
        }));
    });
  }

  render() {
    return (
      <div className={styles.container}>
        {
          !this.state.authenticated &&
          <LoginForm history={this.props.history}/>
        }

        {
          this.state.authenticated &&
          <div>
            <LogoutAdmin />
            <AdminActions addOneUser={this.props.addOneUser}
                          findUser={this.props.findUserByEmail}
                          clearUser={this.props.clearFoundUser}
                          clearUserAddError={this.props.clearUserAddError}
                          user={this.props.currentUser}
                          moveToWhiteList={this.props.moveToWhiteList}
                          addingUserFailed={this.props.addingUserFailed}/>
          </div>
        }

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
