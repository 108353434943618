import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {Store} from 'redux';
import configureStore, {history, IAppState} from './store/Store';
import {Route, Switch} from 'react-router-dom';
import PortalView from './Views/JoinView';
import OrderStatusView from './Views/OrderStatusView';
import AdminView from './Views/AdminView';
import {ConnectedRouter} from 'connected-react-router';
import {StripeProvider} from 'react-stripe-elements';
import HomeView from "./Views/HomeView";
import CheckoutView from "./Views/CheckoutView";
import {incrementSuperProp, logPageView} from "./services/AnalyticsService";

interface IProps {
  store: Store<IAppState>;
}

const routing = (
  <ConnectedRouter history={history}>
    <div>
      {/*<Nav/>*/}
      <Switch>
        <Route exact path="/join" component={PortalView}/>
        <Route exact path="/checkout" component={CheckoutView}/>
        <Route exact path="/success" component={OrderStatusView}/>
        <Route exact path="/status" component={OrderStatusView}/>
        <Route exact path="/admin" component={AdminView}/>
        <Route path="/" component={HomeView}/>
      </Switch>
    </div>
  </ConnectedRouter>
);

interface Props {
  readonly store: Store;
}

class Root extends React.Component<Props> {
  componentDidMount() {
    logPageView(history.location.pathname);
    incrementSuperProp('Number of Visits');
  }
  render() {
    return (
      <Provider store={this.props.store}>
        <StripeProvider apiKey="pk_test_PJJbnf4Y9vkllHFVa0mYBh3i00Cxz2mLDn">
          {routing}
        </StripeProvider>
      </Provider>
    );
  }
}

const store = configureStore();
ReactDOM.render(<Root store={store}/>, document.getElementById(
  'root'
) as HTMLElement);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
