import React from 'react';
import styles from './index.module.scss';
import {IAppState} from '../../store/Store';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import jumboPutt from '../../images/jumbo-putt.png';
import analyzerBg from '../../images/analyzer-bg.png';
import analyzerT from '../../images/analyzer-t.png';
import analyzerBl from '../../images/analyzer-bl.png';
import analyzerBc from '../../images/analyzer-bc.png';
import analyzerBr from '../../images/analyzer-br.png';
import putterBreakdown from '../../images/putter-breakdown.png';
import jllewis from '../../images/JLLewis.png';
import waves from '../../images/waves.svg';
import drillDayOne from '../../images/drill-day-1.png';
import drillDayTwo from '../../images/drill-day-2.png';
import drillDayThree from '../../images/drill-day-3.png';
import drillDayFour from '../../images/drill-day-4.png';
import robRange from '../../images/Rob-Rage-2.png';
import msopLogo from '../../images/msop-logo.svg';
import spLogo from '../../images/sp-logo.svg';
import playButton from '../../images/play-button.svg';
import creators from '../../images/creators.svg';
import {Link} from "react-router-dom";
import Modal from "react-modal";
import YouTube, {Options} from "react-youtube";
import {incrementSuperProp, logEvent} from "../../services/AnalyticsService";
import OrderStatusModal from "../../components/OrderStatusModal";
import SecondaryButton from "../../components/SecondaryButton";
import {User} from "../../reducers/user";
import {endSession, sessionKey} from "../../services/SessionService";
import {checkOrderStatus, checkUserEmail} from "../../actions/user";

const mapStateToProps = (state: IAppState) => ({
  user: state.user.user,
  statusCheckFail: state.user.checkingStatusFailed
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkUserEmail,
      checkOrderStatus
    },
    dispatch
  );

interface DispatchProps {
  readonly checkUserEmail: (email: string) => void;
  readonly checkOrderStatus: (email: string, orderId: string) => void;
}

interface StateProps {
  readonly user?: User;
  readonly statusCheckFail: string;
}

interface LocalState {
  readonly taiSelection: number;
  readonly navFollow: boolean;
  readonly showBlack: boolean;
  readonly showSeason: boolean;
  readonly showAnalyzer: boolean;
  readonly showTrackAndImprove: boolean;
  readonly videoOpen: boolean;
  readonly showOrderStatusModal: boolean;
  readonly cardState: number;
}


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor: 'black',
    padding: 0,
    border: '0',
    width: '100%',
    maxWidth: '80%'
  },
  overlay: {
    backgroundColor: 'rgba(14,18,32,0.95)',
    zIndex: 1000
  }
};

type Props = DispatchProps & StateProps;

export class HomeView extends React.Component<Props, LocalState> {
  state = {
    taiSelection: 0,
    navFollow: false,
    showBlack: false,
    showSeason: false,
    showAnalyzer: false,
    showTrackAndImprove: false,
    videoOpen: false,
    showOrderStatusModal: false,
    cardState: 0
  };

  scroll = 0;
  blackSectionOffset = 0;
  seasonSectionOffset = 0;
  analyzerSectionOffset = 0;
  trackAndImproveOffset = 0;

  cardInterval?: number = undefined;

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleResize, true);

    this.handleResize();
    setTimeout(() => {
      this.handleResize();
    }, 1000);

    // resume session
    let previousSessionEmail = sessionKey();
    if (previousSessionEmail && !this.props.user) {
      endSession();
      this.props.checkUserEmail(previousSessionEmail);
    }

    this.cardInterval = window.setInterval(() => {
      this.setState({
        cardState: this.state.cardState + 1
      })
    }, 4000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);

    if (this.cardInterval) {
      clearInterval(this.cardInterval);
    }
  }

  toggleOrderStatusModal = () => {
    this.setState({
      showOrderStatusModal: !this.state.showOrderStatusModal
    });
  };

  handleResize = () => {
    const blackSection = document.getElementById('blackSectionParagraphOne');
    const seasonSection = document.getElementById('seasonSection');
    const analyzerSection = document.getElementById('analyzerPreviews');
    const trackAndImproveSection = document.getElementById('TAIDrills');

    if (blackSection !== null) {
      this.blackSectionOffset = window.pageYOffset + blackSection.getBoundingClientRect().top;
    }

    if (seasonSection !== null) {
      this.seasonSectionOffset = window.pageYOffset + seasonSection.getBoundingClientRect().top;
    }

    if (analyzerSection !== null) {
      this.analyzerSectionOffset = window.pageYOffset + analyzerSection.getBoundingClientRect().top;
    }

    if (trackAndImproveSection !== null) {
      this.trackAndImproveOffset = window.pageYOffset + trackAndImproveSection.getBoundingClientRect().top;
    }
  };

  handleScroll = () => {
    let toUpdate: {[key: string]: any} = {};
    this.scroll = window.scrollY > this.scroll ? window.scrollY : this.scroll;

    if ((!this.state.navFollow && window.scrollY > 180) || (this.state.navFollow && window.scrollY <= 180)) {
      toUpdate.navFollow = window.scrollY > 180;
    }

    if (this.scroll + window.innerHeight > this.blackSectionOffset + 200) {
      toUpdate.showBlack = true;
    }

    if (this.scroll + window.innerHeight > this.seasonSectionOffset + 350) {
      toUpdate.showSeason = true;
    }

    if (this.scroll + window.innerHeight > this.analyzerSectionOffset + 100) {
      toUpdate.showAnalyzer = true;
    }

    if (this.scroll + window.innerHeight > this.trackAndImproveOffset + 200) {
      toUpdate.showTrackAndImprove = true;
    }

    if (toUpdate !== {}) {
      this.setState(toUpdate as LocalState);
    }
  };

  taiSelected = (val: number) => {
    return this.state.taiSelection === val ? styles.current : '';
  };

  changeTai = (selection: number) => {

    switch (selection) {
      case 1:
        logEvent('Power');
      case 2:
        logEvent('Stroke');
      default:
        logEvent('Alignment');
    }

    this.setState({
      taiSelection: selection
    });
  };

  documentHeight = () => {
    const body = document.body,
      html = document.documentElement;
    return Math.max( body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight );
  };

  join = (type: string) => {
    const height = this.documentHeight();
    let percentage = Math.ceil(((this.scroll / height) * 100)/5)*5;
    if (this.scroll + window.innerHeight === height) {
      // reached bottom of page
      percentage = 100;
    }

    logEvent('Get Started', {
      'Login Type': type,
      'Scroll': `${percentage}`
    });
  };

  taiBarClass = () => {
    switch (this.state.taiSelection) {
      case 1:
        return styles.second;
      case 2:
        return styles.third;
      default:
        return styles.first;
    }
  };

  openVideo = () => {
    logEvent('Play Video');
    incrementSuperProp('Number of video views');
    this.setState({videoOpen: true});
  };

  imageForCardAtState = (card: number) => {

    if ((this.state.cardState + 1) % 6 === card) {
      if ((this.state.cardState % 4) + 2 === 5) {
        return drillDayOne;
      } else if ((this.state.cardState % 4) + 2 === 2) {
        return drillDayTwo;
      } else if ((this.state.cardState % 4) + 2 === 3) {
        return drillDayThree;
      } else if ((this.state.cardState % 4) + 2 === 4) {
        return drillDayFour;
      }
    } else if ((this.state.cardState) % 6 === card) {
      if ((this.state.cardState % 4) + 1 === 1) {
        return drillDayOne;
      } else if ((this.state.cardState % 4) + 1 === 2) {
        return drillDayTwo;
      } else if ((this.state.cardState % 4) + 1 === 3) {
        return drillDayThree;
      } else if ((this.state.cardState % 4) + 1 === 4) {
        return drillDayFour;
      }
    }

    return drillDayOne;
  };

  render() {
    let navFollow = this.state.navFollow ? `${styles.follow} ${styles.animated} ${styles.slideInDown} ${styles.faster}` : '';
    if (window.innerWidth <= 990 && navFollow.length) {
      navFollow = `${styles.follow} ${styles.animated} ${styles.slideInUp} ${styles.faster}`;
    }

    const blackSectionVisible = this.state.showBlack ? styles.visible : '';
    const seasonSectionVisible = this.state.showSeason ? styles.visible : '';
    const analyzerSectionVisible = this.state.showAnalyzer ? styles.visible : '';
    const trackAndImproveSectionVisible = this.state.showTrackAndImprove ? styles.visible : '';

    const cardStateClass = styles[`state${this.state.cardState % 6}`];

    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
        controls: 0,
        modestbranding: 1,
      }
    };

    return (
      <div className={styles.main}>
        <div className={styles.bgGrad}>
          <img className={styles.waves} src={waves} alt="waves"/>
          <div className={`${styles.nav} ${navFollow}`}>
            <div className={styles.followBanner}>
              <div className={styles.navPad}>
                <div className={styles.preNav}>Enter To Compete</div>
                <div className={styles.longNav}>1st place wins $1 Million Dollars</div>
                <div className={styles.shortNav}>1st place: $1 Million</div>
                <Link to={'/join'} onClick={() => this.join('top')} className={styles.joinNow}>Get Started</Link>
              </div>
            </div>
            {
              this.props.user && this.props.user.ordered &&
              <div className={styles.stickBanner}>
                  Registered as {this.props.user.name} <SecondaryButton onClick={this.toggleOrderStatusModal}>Check Order Status</SecondaryButton>
              </div>
            }
          </div>

          <div className={styles.container}>
            <div className={`${styles.row} ${styles.jumbo}`}>
              <Link to={'/join'} onClick={() => this.join('top')} className={`${styles.mainJoin} ${styles.mjLarge}`}>Get Started</Link>
              <div className={styles['col-md-7']}>
                <div className={styles.welcomeText}>
                  <h2>The Best Putter Will Win</h2>
                  <h1>$1 Million Dollars</h1>
                  <Link to={'/join'} onClick={() => this.join('top')} className={`${styles.mainJoin} ${styles.mjSmall}`}>Get Started</Link>
                </div>
                <div className={styles.videoArea}>
                  <div className={styles.videoThumb} onClick={this.openVideo}>
                    <div className={styles.vtTop}>
                      <img src={playButton} alt="play"/>
                      Play Video
                    </div>
                    <div className={styles.vtBottom}>
                      How the<br/>
                      E-Sports<br/>
                      League Works
                    </div>
                  </div>
                </div>
                <div className={styles.intro}>
                  <div className={styles.introTop}>Introducing the</div>
                  <div className={styles.introMid}>World Putting Championship</div>
                  <div className={styles.introBottom}>From the creators of</div>
                  <img src={creators} alt="MSOP + Simply Putting"/>
                </div>
              </div>
              <div className={`${styles['col-md-5']}`}>
                <img className={styles.jumboPutt} src={jumboPutt} alt="Putt"/>
              </div>
            </div>
          </div>

          <div className={`${styles.container} ${styles.jumboBottom}`}>
            <div className={styles.jbPreSub}>The</div>
            <div className={styles.jbSubtitle}>True-to-life</div>
            <div className={styles.jbTitle}>Putting Simulation</div>
          </div>
        </div>
        <div id="blackSection" className={`${styles.blackSection} ${styles.container} ${blackSectionVisible}`}>
          <img className={styles.putterBreakdown} src={putterBreakdown} alt="Putter Breakdown"/>
          <div id="blackSectionParagraphOne" className={`${styles.breakDownSection} ${styles.bdOne}`}>
            <h2>Realistic Physics</h2>
            <p>
              Calculated and refined over hundreds of thousands of putts, the accuracy of physics in this simulation are unparallelled. There is a <b>1 to 1 translation from VR to the course.</b>
            </p>
          </div>
          <div className={`${styles.breakDownSection} ${styles.bdTwo}`}>
            <h2>Millimeter Accuracy</h2>
            <p>
              Utilizing industry standards and the incredibly precise controller of the Oculus Quest, Simply Putting VR gives a customized report on your swing instantly from a quick sample of 5 putts.
            </p>
          </div>
          <div className={`${styles.breakDownSection} ${styles.bdThree}`}>
            <h2>Your Putter,<br/>Your Stroke</h2>
            <p>
              The ultra-light Oculus sensor attaches directly to your putter so when you swing it’s 100% you and the way you are used to putting. You’ll be surprised by how realistic it feels, and what you learn about your putting stroke.
            </p>
          </div>
        </div>
        <div id="seasonSection" className={`${styles.seasonSection} ${seasonSectionVisible}`}>
          <div className={styles.container}>
            <h1>Season Begins Early 2020</h1>
            <div className={styles.locationTypeLarge}>
              <div className={styles.ltTimeline}>
                <div className={styles.ltTlOne}>
                  Online in VR
                  <span/>
                </div>
                <div className={styles.ltTlTwo}>
                  Live On Site in Las Vegas
                  <span/>
                </div>
              </div>
              <div className={`${styles.ltSteps}`}>
                <div className={`${styles.ltStepOne}`}><span>Qualifying Tournaments</span></div>
                <div className={`${styles.ltStepTwo}`}><span>Mid-Season Competitive Tournament Brackets</span></div>
                <div className={`${styles.ltStepThree}`}><span>World Putting Championship</span></div>
              </div>
              <div className={styles.ltAnnotations}>
                <div>Spring 2020</div>
                <div>Summer 2020</div>
                <div>Fall 2020</div>
              </div>
            </div>

            <div className={styles.locationTypeSmall}>
              <div className={styles.ltVR}>

                <div className={styles.ltSeason}>Spring 2020</div>
                <div className={styles.ltEvent}>Qualifying Tournaments</div>

                <div className={styles.ltSeason}>Summer 2020</div>
                <div className={styles.ltEvent}>Mid-Season Competitive Tournament Brackets</div>

              </div>
              <div className={styles.ltLive}>
                <div className={styles.ltSeason}>Fall 2020</div>
                <div className={styles.ltEvent}>World Putting Championship</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.quoteSection} style={{backgroundImage: `url('${jllewis}')`}}>
          <div className={styles.container}>
            <h1>“During my years <span>on the PGA Tour, I would have used Simply Putting VR every night</span> in my hotel room to prepare for my next round and work on my stroke.”</h1>
            <div className={styles.quoteCredit}>-J. L. Lewis</div>
            <div className={styles.quoteInfo}>Two-time PGA Tour Winner</div>
          </div>
        </div>
        <div id="analyzerSection" className={`${styles.analyzerSection} ${analyzerSectionVisible}`} style={{backgroundImage: `url('${analyzerBg}')`}}>
          <div className={styles.container}>
            <h3>Visit the lab with</h3>
            <h1>Swing Analyzer</h1>

            <div className={`${styles.reverseRow}`}>
              <div className={`${styles.analyzerInfo}`}>
                <h3>Everything you need to</h3>
                <h2>Be the best you've ever been</h2>
                <p>Utilizing industry standards and the incredibly precise controller of the Oculus Quest, Simply Putting VR gives a customized report on your swing instantly from a quick sample of 5 putts. </p>
              </div>
              <div className={`${styles.analyzerPreviewContainer}`}>
                <div id="analyzerPreviews" className={styles.analyzerPreview}>
                  <img src={analyzerT} alt="Analyzer Preview Top"/>
                  <img src={analyzerBl} alt="Analyzer Preview Bottom Left"/>
                  <img src={analyzerBc} alt="Analyzer Preview Bottom Center"/>
                  <img src={analyzerBr} alt="Analyzer Preview Bottom Right"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="trackAndImproveSection" className={`${styles.trackAndImproveSection} ${trackAndImproveSectionVisible}`}>
          <div className={styles.container}>
            <h2>Track & Improve Over Time</h2>

            <div className={styles.taiOptions}>
              <button onClick={() => this.changeTai(0)} className={this.taiSelected(0)}>Alignment</button>
              <button onClick={() => this.changeTai(1)} className={this.taiSelected(1)}>Power</button>
              <button onClick={() => this.changeTai(2)} className={this.taiSelected(2)}>Stroke</button>
              <span className={this.taiBarClass()}/>
            </div>

          </div>

          {
            this.state.taiSelection === 0 &&
            <div className={styles.taiLines}>
              <svg style={{top: 90, height: 188}} viewBox="0 0 1444 188" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003"
                   strokeLinecap="round">
                  <g transform="translate(-10.000000, -4829.000000)" strokeWidth="3">
                    <g transform="translate(0.000000, 4502.000000)">
                      <g transform="translate(0.000000, 219.000000)">
                        <path className={styles.alignLinePink} stroke="#FF66DA"
                              d="M11.75,294.207031 L187.370464,153.508666 C190.244148,151.206414 193.649727,149.662271 197.27506,149.017767 L363.352629,119.492866 C368.754988,118.532447 374.321252,119.625503 378.959239,122.55756 L543.987188,226.885449 C548.954666,230.025806 554.972108,231.048136 560.69799,229.724523 L729.492294,190.705492 C730.99328,190.35852 732.453669,189.854846 733.849424,189.202762 L899.760071,111.69085 C905.681754,108.924295 912.526973,108.934018 918.440772,111.717386 L1076.77894,186.240244 C1082.1369,188.762004 1088.28524,189.017292 1093.83381,186.948386 L1268.47085,121.831237 C1270.83342,120.950303 1273.32984,120.482072 1275.85106,120.446998 L1451.75,118 L1451.75,118"
                              />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg style={{top: 120, height: 114}} viewBox="0 0 1444 114" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                  <g transform="translate(-10.000000, -4861.000000)" stroke="#C294F8" strokeWidth="3">
                    <g transform="translate(0.000000, 4502.000000)">
                      <g transform="translate(0.000000, 219.000000)">
                        <path d="M11.75,232.590348 L185.448669,177.004834 C189.561381,175.68872 193.755022,174.640378 198.003257,173.866393 L332.483657,149.365412 C357.544165,144.799639 383.402439,149.969601 404.780893,163.820125 L515.518262,235.563882 C538.419987,250.401293 566.390435,255.235248 592.943553,248.944806 L720.799026,218.655801 C727.744286,217.010466 734.493436,214.62629 740.931094,211.544031 L865.135429,152.07677 C892.531882,138.959739 924.405141,139.005721 951.763633,152.201744 L1045.55835,197.44244 C1070.35356,209.402097 1098.98239,210.611166 1124.69769,200.784695 L1240.39379,156.574267 C1260.37686,148.938227 1282.28201,147.911668 1302.89164,153.645379 L1451.75,195.058594 L1451.75,195.058594"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg style={{top: 60, height: 192}} viewBox="0 0 1456 192" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                  <g transform="translate(2.000000, -4802.000000)" stroke="#00FFD0" strokeWidth="3">
                    <g transform="translate(0.000000, 4502.000000)">
                      <g transform="translate(0.000000, 219.000000)">
                        <path d="M0,270.648438 L179.410687,185.297309 C187.571468,181.414977 196.359269,179.019276 205.361289,178.222741 L357.83331,164.731405 C367.034019,163.917289 376.306068,164.78719 385.19479,167.29845 L529.773172,208.145037 C544.107804,212.194887 559.316517,211.943033 573.509196,207.420774 L715.139201,162.292752 C726.071102,158.809485 737.649786,157.846549 749.006897,159.47616 L893.890073,180.265173 C905.63349,181.950214 917.608308,180.862615 928.855988,177.089433 L1077.08121,127.365346 C1086.76112,124.118093 1096.9961,122.854575 1107.17512,123.650226 L1257.68874,135.415238 C1266.98356,136.141775 1276.33273,135.151891 1285.26932,132.495026 L1451.75,83 L1451.75,83"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <svg style={{top: 0, height: 317}} viewBox="0 0 1456 317" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <defs>
                  <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-1">
                    <stop stopColor="#575A93" stopOpacity="0" offset="0%"/>
                    <stop stopColor="#294B9C" offset="100%"/>
                  </linearGradient>
                  <linearGradient x1="-1.11022302e-14%" y1="48.670033%" x2="98.0993961%" y2="51.2794122%" id="linearGradient-2">
                    <stop stopColor="#00FFD0" offset="0%"/>
                    <stop stopColor="#00E1FF" offset="100%"/>
                  </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(0.000000, -4729.000000)">
                    <g transform="translate(0.000000, 4502.000000)">
                      <g transform="translate(0.000000, 219.000000)">
                        <g transform="translate(0.000000, -0.000000)">
                          <path d="M0,234.853976 L191.26457,199.992674 C191.504331,199.948973 191.744294,199.906382 191.984449,199.864902 L398.617188,164.175097 C410.706156,162.087079 423.115292,162.843205 434.861307,166.383562 L596.799023,215.193075 C617.57019,221.453696 640.001913,218.897046 658.831551,208.122934 L824.321669,113.431307 C837.175081,106.076725 851.836886,102.482977 866.634421,103.060046 L1011.18557,108.697202 C1024.68372,109.223598 1038.09138,106.27998 1050.12762,100.147573 L1201.39438,23.0778367 C1229.85798,8.57576065 1263.5372,8.54529764 1292.02698,22.9958591 L1450.74371,103.5 L1450.74371,103.5 L1451.75,325.225534 L11.75,325.225534 L0,234.853976 Z" className={styles.pathFill} fill="url(#linearGradient-1)"/>
                          <path d="M11.75,234.853976 L215.616756,200.240802 C216.848334,200.0317 218.075887,199.79954 219.298766,199.544443 L394.63697,162.968232 C413.276656,159.079924 432.639025,160.59406 450.44769,167.332629 L581.970238,217.099063 C611.498136,228.272038 644.568187,224.844866 671.177382,207.854196 L808.028009,120.471475 C825.327523,109.425281 845.607117,103.96997 866.113798,104.84611 L1012.54443,111.102301 C1030.71778,111.87875 1048.75872,107.68357 1064.72493,98.9684215 L1200.01533,25.1202112 C1228.83512,9.38893856 1263.54119,8.80744183 1292.87181,23.5644101 L1451.75,103.5 L1451.75,103.5" stroke="url(#linearGradient-2)" strokeWidth="8" strokeLinecap="round"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          }

          {
            this.state.taiSelection === 1 &&
            <div className={styles.taiLines}>
                <svg style={{top: 30, height: 145}} viewBox="0 0 1436 145" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(-10.000000, -4777.000000)" stroke="#FF66DA" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M11.75,184.690274 L191.934759,199.404607 C192.712967,199.468157 193.494006,199.490234 194.274561,199.470743 L370.621006,195.067219 C370.851269,195.061469 371.081428,195.052103 371.311397,195.039125 L552.576161,184.809603 C553.98256,184.730234 555.378085,184.515974 556.743516,184.169773 L729.338639,140.40889 C731.106024,139.960775 732.922263,139.734111 734.745573,139.734111 L907.448398,139.734111 C907.860147,139.734111 908.271815,139.722551 908.682915,139.699446 L1088.40835,129.598346 C1090.78699,129.464659 1093.12812,128.945501 1095.34029,128.061151 L1266.06804,59.8101557 C1268.66551,58.7717799 1271.43717,58.2382972 1274.2345,58.2382972 L1444.5,58.2382972 L1444.5,58.2382972"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 50, height: 149}} viewBox="0 0 1456 149" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(2.000000, -4796.000000)" stroke="#00FFD0" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M0,207.565575 L191.376415,214.34075 C191.62547,214.349568 191.87448,214.35961 192.123437,214.370876 L366.884166,222.279793 C370.124731,222.426447 373.371361,222.365477 376.604135,222.097257 L548.139713,207.865117 C550.545205,207.665536 552.939824,207.35147 555.315426,206.923988 L727.746952,175.895501 C730.413742,175.415621 733.052961,174.793466 735.653302,174.031701 L894.988911,127.354638 C906.01921,124.123333 917.638754,123.435962 928.973093,125.344255 L1069.17965,148.949969 C1083.90002,151.428348 1099.02431,149.516851 1112.6652,143.454011 L1246.89742,83.7930233 C1262.96684,76.6507916 1281.01579,75.3021077 1297.96821,79.9768239 L1451.75,122.382911 L1451.75,122.382911"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 40, height: 147}} viewBox="0 0 1444 147" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(-10.000000, -4792.000000)" stroke="#C294F8" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M11.75,216.180706 L190.438177,199.609988 C191.22884,199.536665 192.018602,199.453928 192.807294,199.361793 L362.872722,179.494852 C369.63093,178.705362 376.290917,177.228178 382.749157,175.086263 L531.585828,125.723659 C548.866955,119.992266 567.384901,119.087545 585.142424,123.107075 L702.594727,149.69316 C721.77193,154.034044 741.803294,152.62513 760.18406,145.642591 L877.588716,101.042571 C897.64917,93.4219478 919.628963,92.4565453 940.280309,98.289014 L1058.01724,131.540939 C1075.2917,136.419689 1093.5609,136.558774 1110.90764,131.9436 L1262.99524,91.4800376 C1268.42899,90.0343669 1273.97498,89.0495603 1279.57425,88.536089 L1451.75,72.7470216 L1451.75,72.7470216"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 0, height: 329}} viewBox="0 0 1445 329" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <defs>
                        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-3">
                            <stop stopColor="#575A93" stopOpacity="0" offset="0%"/>
                            <stop stopColor="#294B9C" offset="100%"/>
                        </linearGradient>
                        <linearGradient x1="-1.11022302e-14%" y1="48.368699%" x2="98.0993961%" y2="51.5692919%" id="linearGradient-4">
                            <stop stopColor="#00FFD0" offset="0%"/>
                            <stop stopColor="#00E1FF" offset="100%"/>
                        </linearGradient>
                    </defs>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-7.000000, -4717.000000)">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <g transform="translate(11.000000, -0.000000)">
                                        <path d="M0.75,258.792969 L184.646904,188.794649 C198.336682,183.583786 213.205643,182.281647 227.592768,185.033695 L393.913226,216.848373 C406.452886,219.247027 419.387078,218.570825 431.608258,214.87767 L611.837112,160.413762 C612.749836,160.137943 613.667478,159.878671 614.589588,159.636073 L825.542535,104.136486 C830.496138,102.833243 835.311252,101.051322 839.91988,98.8158869 L1004.68809,18.8943411 C1020.14067,11.398999 1037.61493,9.14348159 1054.46429,12.4694016 L1211.65291,43.4971103 C1225.71973,46.2737823 1240.21917,45.9929773 1254.16794,42.6737386 L1433.5,6.82121026e-13 L1433.5,6.82121026e-13 L1440.75,325.225534 L0.75,325.225534 L0.75,258.792969 Z" className={styles.pathFill} fill="url(#linearGradient-3)"/>
                                        <path d="M0.75,258.792969 L176.99904,188.263973 C195.625844,180.810146 216.064058,179.131507 235.65744,183.446219 L386.722656,216.712698 C403.936797,220.503471 421.847082,219.676839 438.638865,214.316555 L614.545637,158.163482 C616.944656,157.397665 619.371748,156.722743 621.82172,156.140159 L814.580121,110.303753 C822.899099,108.325567 830.929996,105.288541 838.476247,101.26701 L994.919179,17.8958122 C1016.54986,6.36844097 1041.66084,3.18671425 1065.48298,8.95491376 L1213.71564,44.8473837 C1229.72565,48.7239828 1246.44537,48.585535 1262.38899,44.4443439 L1433.5,0 L1433.5,0" stroke="url(#linearGradient-4)" strokeWidth="8" strokeLinecap="round"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
          }

          {
            this.state.taiSelection === 2 &&
            <div className={styles.taiLines}>
                <svg style={{top: 30, height: 207}} viewBox="0 0 1444 207" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(-10.000000, -4758.000000)" stroke="#C294F8" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M11.75,241.694671 L162.776939,187.744396 C180.055006,181.572275 198.698104,180.272585 216.665445,183.987599 L334.294208,208.309083 C358.781819,213.372259 384.2778,209.077414 405.755301,196.271331 L527.41192,123.73288 C544.511714,113.537031 564.265665,108.677344 584.144068,109.776156 L726.328039,117.63561 C730.106301,117.84446 733.893371,117.838686 737.670978,117.618315 L888.569538,108.815497 C896.824638,108.333927 904.988135,106.830294 912.873071,104.339032 L1063.68015,56.6912284 C1077.30763,52.385597 1091.6997,51.0509611 1105.88655,52.7772551 L1254.6467,70.8787905 C1264.82174,72.1169172 1275.12666,71.7833106 1285.20032,69.8896618 L1451.75,38.5816086 L1451.75,38.5816086" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 60, height: 213}} viewBox="0 0 1436 213" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(-10.000000, -4741.000000)" stroke="#FF66DA" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M11.75,195.058594 L186.432869,230.442602 C190.777982,231.322754 195.287639,230.86961 199.370773,229.142563 L366.857511,158.300547 C369.569188,157.153586 372.483502,156.562597 375.427769,156.562597 L550.316643,156.562597 C553.204377,156.562597 556.063862,155.99408 558.731981,154.889478 L725.622988,85.7965269 C729.774574,84.0777684 734.3529,83.6714405 738.742293,84.6321843 L902.422074,120.458173 C906.122054,121.268018 909.968448,121.10891 913.588901,119.996249 L1089.71728,65.867344 C1091.23464,65.4010206 1092.79801,65.1002911 1094.38008,64.9704089 L1269.09154,50.6272496 C1269.69692,50.57755 1270.29998,50.5027997 1270.89918,50.403191 L1444.5,21.5442986 L1444.5,21.5442986" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 40, height: 193}} viewBox="0 0 1456 193" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003" strokeLinecap="round">
                        <g transform="translate(2.000000, -4744.000000)" stroke="#00FFD0" strokeWidth="3">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <path d="M0,207.565575 L174.298776,213.736162 C185.783302,214.142741 197.210502,211.939638 207.720715,207.292591 L357.068978,141.258885 C366.749542,136.978664 377.217431,134.767733 387.802023,134.767733 L536.774688,134.767733 C546.66316,134.767733 556.456598,132.837999 565.605907,129.086726 L708.24573,70.6035225 C723.500441,64.3489971 740.375347,63.2247301 756.32495,67.4003119 L900.321265,105.098328 C907.894943,107.081105 915.729513,107.881748 923.5477,107.471921 L1078.23827,99.3630901 C1087.17582,98.8945863 1095.96038,96.8511189 1104.1871,93.3268595 L1249.35863,31.1365748 C1263.92134,24.8980334 1280.06958,23.3663952 1295.54549,26.7558178 L1451.75,60.9666274 L1451.75,60.9666274" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg style={{top: 0, height: 328}} viewBox="0 0 1445 328" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <defs>
                        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-1">
                            <stop stopColor="#575A93" stopOpacity="0" offset="0%"/>
                            <stop stopColor="#294B9C" offset="100%"/>
                        </linearGradient>
                        <linearGradient x1="-1.11022302e-14%" y1="48.8616835%" x2="98.0993961%" y2="51.0950467%" id="linearGradient-2">
                            <stop stopColor="#00FFD0" offset="0%"/>
                            <stop stopColor="#00E1FF" offset="100%"/>
                        </linearGradient>
                    </defs>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-7.000000, -4718.000000)">
                            <g transform="translate(0.000000, 4502.000000)">
                                <g transform="translate(0.000000, 219.000000)">
                                    <g transform="translate(11.000000, -0.000000)">
                                        <path d="M0,199.5 L200.869102,215.669059 C205.099705,216.009604 209.350872,216.00866 213.581324,215.666239 L401.28133,200.473409 C409.252443,199.82821 417.079382,197.976416 424.494532,194.981341 L610.09594,120.014543 C615.712992,117.745743 621.572368,116.130563 627.558547,115.200847 L813.615365,86.3042674 C819.95227,85.3200794 826.385438,85.111146 832.77285,85.6820795 L1007.09951,101.264123 C1021.112,102.516618 1035.20477,100.004516 1047.92143,93.9874266 L1215.29804,14.7904785 C1230.26675,7.70779013 1246.76008,4.45328784 1263.29722,5.31916654 L1433.5,14.230921 L1433.5,14.230921 L1440.75,325.225534 L0.75,325.225534 L0,199.5 Z" fill="url(#linearGradient-1)" className={styles.pathFill} />
                                        <path d="M0.75,199.5 L199.165088,215.529537 C204.529824,215.962943 209.920795,215.96253 215.285465,215.528302 L398.084547,200.732163 C408.174564,199.915456 418.082081,197.571413 427.468348,193.780179 L607.848797,120.922193 C614.958989,118.050295 622.37592,116.005763 629.953363,114.828907 L809.88984,86.8828806 C818.694508,85.5154231 827.642445,85.3308498 836.496014,86.3340624 L998.36346,104.675532 C1018.01256,106.902004 1037.88067,103.252826 1055.45613,94.1893131 L1213.08251,12.9027842 C1229.44476,4.4649164 1247.81873,0.707613043 1266.17991,2.04487107 L1433.5,14.230921 L1433.5,14.230921" stroke="url(#linearGradient-2)" strokeWidth="8" strokeLinecap="round"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
          }

          <div className={styles.drillOver}>
            <div className={styles.container}>
              <div className={`${styles.drills} ${styles.row}`}>
                <div className={styles['col-md-4']}>
                  <h3>
                    Dozens of putting drills used by professionals
                  </h3>
                  <p>
                    Customized plans based on your swing style
                  </p>
                </div>
                <div className={styles['col-md-8']}>
                  <div id="TAIDrills" className={`${styles.drillDays}`}>
                    <img src={this.imageForCardAtState(0)} className={cardStateClass} alt="Drill Day"/>
                    <img src={this.imageForCardAtState(1)} className={cardStateClass} alt="Drill Day"/>
                    <img src={this.imageForCardAtState(2)} className={cardStateClass} alt="Drill Day"/>
                    <img src={this.imageForCardAtState(3)} className={cardStateClass} alt="Drill Day"/>
                    <img src={this.imageForCardAtState(4)} className={cardStateClass} alt="Drill Day"/>
                    <img src={this.imageForCardAtState(5)} className={cardStateClass} alt="Drill Day"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.readySection}>
          <h1>Are<br/>you<br/>ready?</h1>
          <Link to={'/join'} onClick={() => this.join('bottom')} className={styles.joinReady}>Get Started</Link>
          <img src={robRange} alt="Ready?"/>
        </div>

        <div className={styles.footer}>
          <div className={styles.fBanner}>
            Now Accepting Invite-only Pre-Orders for 2020 Season. Spots limited.
          </div>

          <div className={styles.footerLogos}>
            <div className={styles.fLogosTitle}>Brought to you by</div>
            <div className={styles.fLogoImages}>
              <img src={spLogo} alt="Simply Putting"/>
              <img src={msopLogo} alt="MSOP"/>
            </div>
          </div>

          <div className={styles.footerLinks}>
            <button onClick={this.toggleOrderStatusModal}>Check Order Status</button>
            <Link to={'/'}>FAQ</Link>
            <Link to={'/'}>Contact Us</Link>
            <Link to={'/'}>Terms Of Use</Link>
            <Link to={'/'}>Privacy Policy</Link>
          </div>

          <div className={styles.footerCopyright}>
            © 2019 World Putting Organization Incorporated
          </div>
        </div>
        <Modal
          isOpen={this.state.videoOpen}
          onRequestClose={() => this.setState({videoOpen: false})}
          style={customStyles}
          contentLabel="Video"
          ariaHideApp={false}
          closeTimeoutMS={300}>
          <div className={styles.modal}>
            <YouTube
              videoId="mKGsRjbqhHo"
              opts={opts as Options}
            />
          </div>
        </Modal>

        <OrderStatusModal show={this.state.showOrderStatusModal}
                          close={this.toggleOrderStatusModal}
                          checkOrderStatus={this.props.checkOrderStatus}
                          statusCheckFail={this.props.statusCheckFail}
                          user={this.props.user}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
