import React from 'react';
import styles from './index.module.scss';
import {User} from "../../reducers/user";
import SecondaryButton from "../SecondaryButton";
import SPInput from "../SPInput";
import AdminFoundUser from "../AdminFoundUser";

interface LocalState {
}

interface Props {
  readonly addOneUser: (email: string, created: () => void) => void;
  readonly moveToWhiteList: (user: User, added: () => void) => void;
  readonly clearUser: () => void;
  readonly clearUserAddError: () => void;
  readonly findUser: (email: string) => void;
  readonly user?: User;
  readonly addingUserFailed: string;
}

class AdminActions extends React.Component<Props, LocalState> {

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.user === undefined && nextProps.user !== undefined) {
      this.setState({
        email: ''
      });
    }
  }

  componentWillUnmount() {
    this.props.clearUser();
  }

  state = {
    email: ''
  };

  findAUser = () => {
    this.props.clearUser();
    this.props.clearUserAddError();

    this.props.findUser(this.state.email);
  };

  handleEmailChange = (name: string, text: string) => {
    this.props.clearUser();
    this.props.clearUserAddError();
    this.setState({
      email: text
    });
  };

  addNewUser = () => {
    this.props.clearUser();
    this.props.clearUserAddError();

    this.props.addOneUser(this.state.email, () => {
      this.setState({
        email: ''
      });
      alert('added user');
    });
  };

  messageForErrorType = (type?: string) => {
    if (type === 'in-use') {
      return 'An account with the provided email already exists';
    } else if (type === 'unknown') {
      return 'Unable to add a user with that email';
    }
    return '';
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.emailTool}>
          <SPInput name="email"
                   placeholder="Email"
                   error={this.messageForErrorType(this.props.addingUserFailed)}
                   value={this.state.email}
                   onChange={this.handleEmailChange}/>
          <SecondaryButton onClick={this.findAUser}>Find</SecondaryButton>
          <SecondaryButton onClick={this.addNewUser}>Add User</SecondaryButton>
        </div>
        <AdminFoundUser moveToWhiteList={this.props.moveToWhiteList} user={this.props.user}/>
      </div>
    );
  }
}

export default AdminActions;
