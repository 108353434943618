import axios from 'axios';
import {User} from "../reducers/user";

const API_BASE = 'https://us-central1-sp-vr-web.cloudfunctions.net';


export const findUser = (email: string, admin: boolean = false) => {
  return axios.post(`${API_BASE}/user/find`, {
    email
  });
};

export const fetchOrderStatus = (email: string, orderId: string) => {
  return axios.post(`${API_BASE}/user/status`, {
    email,
    order_id: orderId
  });
};

export const cancelOrder = (user: User, reason: string) => {
  return axios.post(`${API_BASE}/user/${user.id}/cancel`, {
    reason,
    order_id: user.order_id || ''
  });
};

export const addUser = (email: string, sendEmail: boolean) => {
  return axios.post(`${API_BASE}/user/`, {
    email,
    noEmail: !sendEmail
  });
};

export const updateUser = (user: User, canOrder: boolean) => {
  return axios.patch(`${API_BASE}/user/${user.id}`, {
    can_order: canOrder,
  });
};

export const purchase = (user: User, source: string) => {
  return axios.post(`${API_BASE}/user/${user.id}/purchase`, {
    source,
    name: user.name,
    phone: user.phone,
    ...user.address
  });
};

export const getAll = (page: number = 0) => {
  const numberPerPage = 20;
  const offset = page * numberPerPage;
  return axios.get(`${API_BASE}/user?offset=${offset}`);
};