import React from 'react';
import styles from './index.module.scss';
import {User} from "../../reducers/user";

interface LocalState {
}

interface Props {
  readonly moveToWhiteList: (user: User, added: () => void) => void;
  readonly user?: User;
}

class AdminFoundUser extends React.Component<Props, LocalState> {

  addToWhitelist = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.props.user) {
      this.props.moveToWhiteList(this.props.user, () => {
      });
    }
  };

  render() {
    if (this.props.user === undefined) {
      return '';
    }

    return (
      <div className={styles.container}>
        <h3>Email</h3>
        <p>{this.props.user.email}</p>
        <h3>Name</h3>
        <p>{this.props.user.name}</p>
        <h3>Address</h3>
        <p>
          {this.props.user.address.line1}<br/>
          {
            this.props.user.address.line2 &&
              <div>
                {this.props.user.address.line2}
              </div>
          }

          {this.props.user.address.city}<br/>
          {this.props.user.address.state}<br/>
          {this.props.user.address.zipcode}<br/>
        </p>
        <h3>Can Order</h3>
        <p>{this.props.user.can_order ? 'YES' : 'NO'}</p>
        {
          !this.props.user.can_order &&
          <button onClick={this.addToWhitelist}>Move to white list</button>
        }
        <h3>Did Order</h3>
        <p>{this.props.user.ordered ? 'YES' : 'NO'}</p>
        <h3>Fulfilled</h3>
        <p>{this.props.user.fulfilled ? 'YES' : 'NO'}</p>
      </div>
    );
  }
}

export default AdminFoundUser;
