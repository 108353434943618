import React from 'react';
import firebase from '../../firebase';
import clearIcon from '../../images/clear.svg';
import styles from './index.module.scss';

const logOutUser = () => {
  firebase.auth().signOut();
};

const LogoutAdmin: React.FC = () => {
  return <button className={styles.logout} onClick={logOutUser}><img src={clearIcon} alt="Log out"/></button>;
};
export default LogoutAdmin;
