import React from 'react';
import styles from './index.module.scss';
import {IAppState} from '../../store/Store';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {User} from '../../reducers/user';
import {
  logoutUser,
  placeOrder,
  checkOrderStatus,
  checkUserEmail,
  clearStatusFail,
  goToPage} from '../../actions/user';
import CheckoutForm from '../../components/CheckoutForm';
import {Elements} from 'react-stripe-elements';
import SPInput from '../../components/SPInput';
import {incrementSuperProp, logEvent} from '../../services/AnalyticsService';
import BreakdownModule from "../../components/BreakdownModule";
import EmailChecker from "../../components/EmailChecker";
import Banner from "../../components/Banner";
import {endSession, sessionKey} from "../../services/SessionService";
import UpArrow from '../../images/up-arrow.svg';
import closeIcon from '../../images/close-modal.svg';
import IcnEmail from '../../images/icn-email.svg';
import PrimaryButton from "../../components/PrimaryButton";
import Modal from "react-modal";

const mapStateToProps = (state: IAppState) => ({
  user: state.user.user,
  statusCheckFail: state.user.checkingStatusFailed,
  notFoundEmail: state.user.notFoundEmail
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logoutUser,
      placeOrder,
      checkOrderStatus,
      checkUserEmail,
      clearStatusFail,
      goToPage
    },
    dispatch
  );

interface DispatchProps {
  readonly logoutUser: () => void;
  readonly goToPage: (path: string) => void;
  readonly clearStatusFail: () => void;
  readonly placeOrder: (source: string, user: User) => void;
  readonly checkOrderStatus: (email: string, orderId: string) => void;
  readonly checkUserEmail: (email: string) => void;
}

interface StateProps {
  readonly user?: User;
  readonly statusCheckFail: string;
  readonly notFoundEmail: string;
  readonly history: History;
}

interface LocalState {
  readonly orderId: string;
  readonly showWaitlistModal: boolean;
}

type Props = DispatchProps & StateProps;

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor: '#EEEFF5',
    maxWidth: 500
  },
  overlay: {
    backgroundColor: 'rgba(14,18,32,0.95)',
    zIndex: 100
  }
};

export class CheckInView extends React.Component<Props, LocalState> {
  state = {
    orderId: '',
    showWaitlistModal: false
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.notFoundEmail.length === 0 && nextProps.notFoundEmail.length > 0) {
      this.setState({
        showWaitlistModal: true
      });
    }
  }

  componentDidMount() {
    let previousSessionEmail = sessionKey();
    if (previousSessionEmail) {
      endSession();
      this.props.checkUserEmail(previousSessionEmail);
    }
  }

  logout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  orderIdChanged = (name:string, text: string) => {
    this.props.clearStatusFail();
    this.setState({
      orderId: text
    });
  };

  checkStatus = () => {
    if (this.props.user) {
      logEvent('Check Order Status');
      incrementSuperProp('Number of Check Ins');
      this.props.checkOrderStatus(this.props.user.email, this.state.orderId);
    }
  };

  statusCheckError = () => {
    if (this.props.statusCheckFail === 'not-found') {
      return 'The order your provided does not match your email';
    } else if (this.props.statusCheckFail.length > 0) {
      return 'We\'re not sure what went wrong but we\'re looking into it!';
    }
    return '';
  };

  closeWaitlistModal = () => {
    this.props.goToPage('/');
  };

  render() {
    const isDisabled = this.props.user === undefined ? styles.disabled : '';
    return (
      <div>
        <Banner/>
        <div className={styles.main}>
          <div className={styles.content}>
            <EmailChecker checkEmail={this.props.checkUserEmail} user={this.props.user}/>
            <div className={`${styles.paymentModule} ${isDisabled}`}>
              <div className={`${styles.disabledMessage} ${isDisabled}`}>
                <img src={UpArrow} alt="Up"/>
                Verify your email above first
              </div>
              {
                ((this.props.user && !this.props.user.ordered) || !this.props.user) &&
                  <div>
                      <h1>Payment Details</h1>

                      <Elements>
                          <CheckoutForm user={this.props.user} placeOrder={this.props.placeOrder}/>
                      </Elements>
                  </div>
              }

              {
                this.props.user && this.props.user.ordered &&
                <div>
                    <h1>Your order has been placed!</h1>
                    <div className={styles.alreadyOrdered}>You've already ordered before. If you'd like to check on your order status, enter your order number (can be found in your confirmation email).</div>

                    <SPInput label="Order Number"
                             name="order_id"
                             placeholder=""
                             error={this.statusCheckError()}
                             onChange={this.orderIdChanged}/>
                    <div className={styles.statusCheck}>
                        <PrimaryButton onClick={this.checkStatus}>Check Order Status</PrimaryButton>
                    </div>
                </div>
              }
            </div>
          </div>
          <div className={styles.sidebar}>
            <BreakdownModule title={'Order Summary'} showCheckout={false} page="Checkout Page"/>
          </div>
        </div>

        <Modal
          isOpen={this.state.showWaitlistModal}
          onRequestClose={this.closeWaitlistModal}
          style={customStyles}
          contentLabel="Waitlist Info"
          ariaHideApp={false}
          closeTimeoutMS={300}
        >

          <div className={styles.modal}>
            <button className={styles.closeModal} onClick={this.closeWaitlistModal}><img src={closeIcon} alt="close"/></button>
            <div className={styles.waitlist}>
              <img src={IcnEmail} alt="Email"/>
              <div className={styles.wSubtitle}>
                Invite-only during Pre-Orders
              </div>
              <div className={styles.wTitle}>
                We’ll notify you when it’s public
              </div>

              <p>
                Since the email you entered is not our our list of MSOP participants, we’ll email you when it’s publicly available.
              </p>
              <p>
                If you think you’ve reached an error and you should be on the list, please email us at <a href="mailto:help@simplyputting.com">help@simplyputting.com</a>
              </p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckInView);
