import React, {FormEvent} from 'react';
import styles from './index.module.scss';
import firebase from '../../firebase';
import SPInput from "../SPInput";
import PrimaryButton from "../PrimaryButton";

interface LocalState {
  readonly email: string;
  readonly password: string;
  readonly error: any;
}

interface Props {
  readonly history: History;
}

class LoginForm extends React.Component<Props, LocalState> {
  state = {
    email: '',
    password: '',
    error: null,
  };

  handleInputChange = (name: string, text: string) => {
    const toUpdate: any = {};
    toUpdate[name] = text;
    this.setState(toUpdate);
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = this.state;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        // this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({ error: error });
      });
  };

  render() {
    const { error } = this.state;
    return (
      <div className={styles.loginContainer}>
        <div>
          <div>
            <h3>Log In</h3>
          </div>
        </div>
        {error !== null ? (
          <div>
            <div>
              {/*<p>{error.message}</p>*/}
            </div>
          </div>
        ) : null}
        <div>
          <div>
            <form onSubmit={this.handleSubmit}>
              <SPInput placeholder="Email" name="email" onChange={this.handleInputChange}/>
              <SPInput placeholder="Password" name="password" type="password" onChange={this.handleInputChange}/>
              <PrimaryButton>Login</PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginForm;
