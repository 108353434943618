import React from 'react';
import styles from './index.module.scss';

const Banner: React.FC<{}> = () => {
  return (
    <div className={styles.main}>
      Now Accepting Invite-only Pre-Orders for 2020 Season. Spots limited.
    </div>
  )
};

export default Banner;
