import firebase from 'firebase';
const config = {
  apiKey: 'AIzaSyDpnk-13QShqrXRRgtsD8cIzhczhWOmyRo',
  authDomain: 'sp-vr-web.firebaseapp.com',
  databaseURL: 'https://sp-vr-web.firebaseio.com',
  projectId: 'sp-vr-web',
  storageBucket: 'sp-vr-web.appspot.com',
  messagingSenderId: '434292313102',
  appId: '1:434292313102:web:5d18b3228b34a9af'
};
firebase.initializeApp(config);
export default firebase;