import React, {FormEvent} from 'react';
import styles from './index.module.scss';
import {User} from '../../reducers/user';
import {logEvent} from "../../services/AnalyticsService";
import SPInput from "../SPInput";
import PrimaryButton from "../PrimaryButton";
import {isValidEmail} from "../../services/Validation";
import IcnEmail from '../../images/icn-email.svg';

interface LocalState {
  readonly email: string;
  readonly emailError: string;
  readonly canSubmit: boolean;
}

interface Props {
  readonly user?: User;
  readonly checkEmail: (email: string) => void;
}

class EmailChecker extends React.Component<Props, LocalState> {
  state = {
    email: '',
    emailError: '',
    canSubmit: false
  };

  emailCheck = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.canSubmit) {
      return;
    }

    if (!isValidEmail(this.state.email)) {
      this.setState({
        emailError: 'Invalid email address format. Please use “example@gmail.com”'
      });
      return;
    }

    logEvent('Check Email Address');
    this.props.checkEmail(this.state.email);
  };

  handleEmailChange = (name: string, value: string) => {
    this.setState({
      email: value,
      emailError: '',
      canSubmit: value.length > 0
    });
  };

  render() {
    const alreadyInWaitingList = this.props.user !== undefined && !this.props.user.can_order;

    if (this.props.user !== undefined) {
      return (
        <div className={styles.main}>
          <div className={styles.verified}>
            <img src={IcnEmail} alt="Email"/>
            <div className={styles.vSubtitle}>
              Email verified!
            </div>
            <div className={styles.vTitle}>
              You can now proceed with your order
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`${styles.main}`}>
        {
          alreadyInWaitingList &&
            <div>
              Sorry you're not able to pre-order yet. You're already on the waiting list - you will get an email with more information once you are able to pre-order.
            </div>
        }
        {
          !alreadyInWaitingList &&
            <div>
              <div className={styles.title}>Enter your email address to continue</div>
                <form onSubmit={this.emailCheck}>
                    <div className={styles.emailForm}>
                        <div className={styles.input}>
                            <SPInput name="email" placeholder={'Email'} error={this.state.emailError} value={this.state.email} onChange={this.handleEmailChange}/>
                        </div>
                        <PrimaryButton disabled={!this.state.canSubmit}>Continue</PrimaryButton>
                    </div>
                </form>
            </div>
        }
      </div>
    );
  }
}

export default EmailChecker;
