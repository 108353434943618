/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import {applyMiddleware, combineReducers, createStore, Store} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {logPageView} from '../services/AnalyticsService';
// Import reducers and state type
import {
  userReducer,
  UserState,
} from '../reducers/user';
import {adminReducer, AdminState} from '../reducers/admin';

// Create an interface for the application state
export interface IAppState {
  readonly router: any;
  readonly user: UserState;
  readonly admin: AdminState;
}

export const history = createBrowserHistory();

// log page views
history.listen(location => {
  logPageView(location.pathname);

  // reset scroll
  window.scrollTo(0, 0);
});

// Create the root reducer
const rootReducer = combineReducers<IAppState>({
  router: connectRouter(history),
  user: userReducer,
  admin: adminReducer
});

// Create a configure store function of type `IAppState`
export default function configureStore(): Store<IAppState, any> {
  const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(
      routerMiddleware(history),
      thunk
    )
  );
  return store;
}