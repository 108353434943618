import React from 'react';
import styles from './index.module.scss';
import {IAppState} from '../../store/Store';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {User} from '../../reducers/user';
import {Redirect} from 'react-router';
import {requestOrderCancel} from '../../actions/user';
import waves from '../../images/waves.svg';
import rob from '../../images/rob-rings.png';
import quest from '../../images/quest.png';
import simplyPutting from '../../images/simply-putting.png';
import golfScope from '../../images/golf-scope.png';
import StatusModule from "../../components/StatusModule";
import {logEvent} from "../../services/AnalyticsService";

const mapStateToProps = (state: IAppState) => ({
  user: state.user.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      requestOrderCancel
    },
    dispatch
  );

interface DispatchProps {
  readonly requestOrderCancel: (reason: string) => void;
}

interface StateProps {
  readonly user?: User;
}

interface LocalState {
  readonly reason: string;
}

type Props = DispatchProps & StateProps;

class OrderStatusView extends React.Component<Props, LocalState> {
  state = {
    reason: ''
  };


  render() {
    if (this.props.user !== undefined) {
      return (
        <div className={styles.view}>
          <div className={styles.bgGrad}>
            <img className={styles.waves} src={waves} alt="waves"/>
            <div className={styles.header}>
              <img src={rob} alt="Putt"/>
              <div className={styles.headerTitle}>
                <h1>Success!</h1>
                <div>
                  You're confirmed for the<br/>2020 Season!
                </div>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.content}>
                <div className={styles.statusModule}>
                  <div className={styles.statusHead}>
                    <h1>What's next?</h1>
                    <div>
                      We’ll be in touch with updates as your order gets closer to shipping! For now there are a few things you can do to get ready.
                    </div>
                  </div>
                  <div className={`${styles.statusStep} ${styles.stepOne}`}>
                    <h3>Get your Headset</h3>
                    <p>
                      The beginning tournament will be played in VR on the Oculus Quest. This new system from Oculus is very popular and supplies are limited so please secure yours as soon as possible. Get to know the system a little and we’ll notify you when your attachment is ready to ship.
                    </p>
                    <div className={styles.whereToBuy}>
                      <div>
                        <img src={quest} alt="Oculus"/>
                      </div>
                      <div>
                        Where to Buy<br/>An Oculus Quest
                        <a href="https://www.oculus.com/quest/where-to-buy/" onClick={() => logEvent('Buy An Oculus')} rel="noopener noreferrer" target="_blank">Search</a>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.statusStep} ${styles.stepTwo}`}>
                    <h3>Practice up! And try these other apps from Simply Putting!</h3>
                    <div className={styles.appInfo}>
                      <div className={styles.aiIcon}>
                        <img src={simplyPutting} alt="Simply Putting"/>
                      </div>
                      <div className={styles.aiDescription}>
                        <h3>Simply Putting for iPhone and iPad</h3>
                        <div>
                          A free Green-reading game featured by Apple
                        </div>
                        <a href="https://itunes.apple.com/us/app/simply-putting/id1454843734?mt=8" onClick={() => logEvent('Simply Putting App')} rel="noopener noreferrer" target="_blank">Check it out</a>
                      </div>
                    </div>
                    <div className={styles.appInfo}>
                      <div className={styles.aiIcon}>
                        <img src={golfScope} alt="Golf Scope"/>
                      </div>
                      <div className={styles.aiDescription}>
                        <h3>Golf Scope</h3>
                        <div>
                          Green reading Augmented Reality (AR) with multiple features by Apple including app of the day.
                        </div>
                        <a href="https://apps.apple.com/us/app/golf-scope-green-reading-app/id1306722711?ls=1" onClick={() => logEvent('Golf Scope App')} rel="noopener noreferrer" target="_blank">Check it out</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.sidebar}>
                <StatusModule title="Order Summary" user={this.props.user} requestOrderCancel={this.props.requestOrderCancel}/>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <Redirect to={'/'}/>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusView);
