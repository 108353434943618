import React from 'react';
import {CardElement, ReactStripeElements} from 'react-stripe-elements';
import styles from './index.module.scss';

interface LocalState {
  readonly isFocused: boolean;
  readonly error: string;
}

interface Props {
  readonly cardInError: boolean;
  readonly onChange: (e: ReactStripeElements.ElementChangeResponse) => void;
}

let cardStyle = {
  base: {
    fontSize: '18px',
    border: 'solid 1px red'
  }
};

class CardInput extends React.Component<Props, LocalState> {
  state = {
    isFocused: false,
    error: ''
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.cardInError === false && nextProps.cardInError === true && this.state.error.length === 0) {
      this.setState({
        error: 'Card is required'
      });
    }

    if (this.props.cardInError === true && nextProps.cardInError === false) {
      this.setState({
        error: ''
      });
    }
  }

  handleFocus = () => {
    this.setState({
      isFocused: true
    })
  };

  handleBlur = () => {
    this.setState({
      isFocused: false
    });
  };

  handleChange = (event: ReactStripeElements.ElementChangeResponse) => {
    this.props.onChange(event);
    if (event.error && event.error.message) {
      this.checkError(event.error.message || '')
    } else {
      this.setState({
        error: ''
      });
    }
  };

  checkError = (message: string) => {
      if (message.length > 0) {
        this.setState({
          error: message
        })
      } else {
        this.setState({
          error: ''
        });
      }
  };

  render() {
    const isFocused = this.state.isFocused ? styles.focused : '';
    const isInError = this.state.error.length > 0 ? styles.errored : '';
    return (
      <div>
        <label className={styles.main}>
          <span>Card</span>
          <CardElement className={`${styles.cardInput} ${isFocused} ${isInError}`}
                       style={cardStyle}
                       onFocus={this.handleFocus}
                       onBlur={this.handleBlur}
                       onChange={this.handleChange}
          />
        </label>
        <div className={`${styles.errorMessage} ${isInError}`}>
          <span>{this.state.error}</span>
        </div>
      </div>
    );
  }
}

export default CardInput;