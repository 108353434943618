import React from 'react';
import styles from './index.module.scss';
import IcnWarn from '../../images/icn-warn.svg';
import { AsYouType } from 'libphonenumber-js';

interface LocalState {
  readonly text: string;
  readonly isActive: boolean;
}

interface Props {
  readonly type?: 'text' | 'password' | 'phone';
  readonly label?: string;
  readonly name: string;
  readonly value?: string;
  readonly error?: string;
  readonly placeholder: string;
  readonly onChange: (name: string, text: string) => void;
  readonly readOnly?: boolean;
}

class SPInput extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      text: props.value || '',
      isActive: false
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        text: nextProps.value || ''
      });
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value;

    if (this.props.type === 'phone') {
      let clean = val.replace(/[^0-9]/g, '');
      if (clean.length > 10) {
        clean = clean.slice(0, -1);
      }
      val = new AsYouType('US').input(clean);
    }

    this.setState({text: val});
    this.props.onChange(this.props.name, val);
  };

  handleFocus = () => {
    this.setState({isActive: true})
  };

  handleBlur = () => {
    this.setState({isActive: false})
  };

  render() {
    const isActive = this.state.isActive || this.state.text.length > 0 ? styles.active : '';
    const isFocused = this.state.isActive ? styles.focused : '';
    const isInError = this.props.error && this.props.error.length > 0 ? styles.errored : '';
    return (
      <div>
        {
          this.props.label !== undefined &&
          <label className={styles.label} htmlFor={this.props.name}>
            {this.props.label}
          </label>
        }
        <div className={`${styles.main} ${isFocused} ${isActive} ${isInError}`}>
          <input className={`${styles.input} ${isActive}`}
                 disabled={this.props.readOnly}
                 id={this.props.name}
                 value={this.state.text}
                 type={this.props.type || 'text'}
                 placeholder={this.props.placeholder}
                 onChange={this.handleInputChange}
                 onFocus={this.handleFocus}
                 onBlur={this.handleBlur}
          />
          <div className={`${styles.fob} ${isInError}`}>
            <img src={IcnWarn} alt="Warn"/>
          </div>
        </div>
        <div className={`${styles.errorMessage} ${isInError}`}>
          <span>{this.props.error}</span>
        </div>
      </div>
    )
  }
}

export default SPInput;
