import React from 'react';
import styles from './index.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {checkUserEmail} from '../../actions/user';
import {IAppState} from '../../store/Store';
import {endSession, sessionKey} from '../../services/SessionService';
import {User} from '../../reducers/user';
import Banner from "../../components/Banner";
import ProductHead from '../../images/product-head.png';
import IncGolf from '../../images/inc-golf.svg';
import IncTrack from '../../images/inc-track.svg';
import IncBot from '../../images/inc-bot.svg';
import IncDrill from '../../images/inc-drill.svg';
import IncAnalyze from '../../images/inc-analyze.svg';
import IcnWarn from '../../images/icn-warn-yellow.svg';
import BreakdownModule from "../../components/BreakdownModule";
import {Link} from "react-router-dom";
import {logEvent} from "../../services/AnalyticsService";

const mapStateToProps = (state: IAppState) => ({
  user: state.user.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkUserEmail
    },
    dispatch
  );

interface DispatchProps {
  readonly checkUserEmail: (email: string) => void;
  readonly restartSession: () => void;
}

interface StateProps {
  readonly user?: User;
}

interface LocalState {
  readonly checkingForUser: boolean;
  readonly tabSelected: number;
}

type Props = DispatchProps & StateProps;

class JoinView extends React.Component<Props, LocalState> {
  state = {
    checkingForUser: true,
    tabSelected: 0
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.user && !this.props.user) {
      this.setState({
        checkingForUser: false
      });
    }
  }

  componentDidMount() {
    let previousSessionEmail = sessionKey();
    if (previousSessionEmail) {
      endSession();
      this.props.checkUserEmail(previousSessionEmail);
    } else {
      this.setState({
        checkingForUser: false
      });
      // logPageView(window.location.pathname)
    }

  }

  isTabSelected = (index: number) => {
    return index === this.state.tabSelected ? styles.selected : '';
  };

  tabClick = (index: number) => {
    if (index === 1) {
      logEvent('FAQ Join Page');
    } else if (index === 2) {
      logEvent('Comments');
    }
    this.setState({
      tabSelected: index
    })
  };

  showTabContentIndex = (index: number) => {
    return index === this.state.tabSelected ? styles.visible : '';
  };

  render() {
    return (
      <div>
        <Banner/>
        <div className={`${styles.mobilePriceModule} ${styles.animated} ${styles.slideInUp} ${styles.fast}`}>
          <span>$499.00</span>
          <Link className={styles.continue} to={'/checkout'}>Order Now</Link>
        </div>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.productHeadContainer}>
              <img className={styles.productHead} src={ProductHead} alt="World Putting Championship"/>

              <div className={styles.phName}>
                <h1>
                  World<br/>
                  Putting<br/>
                  Championship
                </h1>
                <div>
                  Tournament Entry + Putter Attachment
                </div>
              </div>
            </div>
            <div className={styles.contentPadding}>
              <h1>
                Everything you need to compete and win $1,000,000 for just $499
              </h1>
              <h2>
                <img src={IcnWarn} alt="Warning"/> Oculus Quest not included
              </h2>

              <div className={styles.included}>
                <h3>
                  World Putting Championship entry and Putter attachment plus…
                </h3>
                <div className={styles.includedItems}>
                  <div className={styles.includedItem}>
                    <div className={styles.includedLogo}>
                      <img src={IncGolf} alt="Golf"/>
                    </div>
                    <label>MSOP Course +2</label>
                  </div>
                  <div className={styles.includedItem}>
                    <div className={styles.includedLogo}>
                      <img src={IncBot} alt="Bot"/>
                    </div>
                    <label>Smart Bot Trainer</label>
                  </div>
                  <div className={styles.includedItem}>
                    <div className={styles.includedLogo}>
                      <img src={IncAnalyze} alt="Analyze"/>
                    </div>
                    <label>Swing Analyzer</label>
                  </div>
                  <div className={styles.includedItem}>
                    <div className={styles.includedLogo}>
                      <img src={IncTrack} alt="Track"/>
                    </div>
                    <label>Progress Tracking</label>
                  </div>
                  <div className={styles.includedItem}>
                    <div className={styles.includedLogo}>
                      <img src={IncDrill} alt="Drills"/>
                    </div>
                    <label>Customized Drills</label>
                  </div>
                </div>
              </div>

              <div className={styles.tabs}>
                <div className={styles.tabOptions}>
                  <button className={`${styles.tabOption} ${this.isTabSelected(0)}`} onClick={() => this.tabClick(0)}>
                    Description
                  </button>
                  <button className={`${styles.tabOption} ${this.isTabSelected(1)}`} onClick={() => this.tabClick(1)}>
                    FAQ
                  </button>
                  <button className={`${styles.tabOption} ${this.isTabSelected(2)}`} onClick={() => this.tabClick(2)}>
                    Comments
                  </button>
                </div>
                <div className={`${styles.tabDescription} ${this.showTabContentIndex(0)}`}>
                  Comes with everything you need to train and be ready for the 2020 season. Includes Putter Attachment for Oculus Quest controller, Entry to the World Putting Championship 2020 season,  full access to the swing analyzer, personal progress tracking, customized drills designed to correct problem areas, play the MSOP stadium course designed by Jack Nicklaus in multiple versions and formats, train against a smart bot playing rounds that is matched to compete with you at your skill level.
                </div>
                <div className={`${styles.tabDescription} ${this.showTabContentIndex(1)}`}>
                  FAQ
                </div>
                <div className={`${styles.tabDescription} ${this.showTabContentIndex(2)}`}>
                  Comments
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sidebar}>
            <BreakdownModule title={'Pre-Order Price'} showCheckout={true} page="Join Page"/>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinView);
