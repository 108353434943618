// Import redux types
import {ActionCreator, AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {addUser, cancelOrder, fetchOrderStatus, findUser, purchase} from '../services/UserService';
import {push} from 'connected-react-router';

// Import Character Typing
import {User, UserPurchase} from '../reducers/user';
import {endSession, sessionKey, startSession} from "../services/SessionService";
import {IAppState} from "../store/Store";
import {logEvent, setSuperProps} from "../services/AnalyticsService";

// Create Action Constants
export enum UserActionTypes {
  checkEmailRequest = 'user.checkEmailRequest',
  checkEmailNotFound = 'user.checkEmailNotFound',
  checkEmailRequestFailed = 'user.checkEmailRequestFailed',
  checkEmailResponse = 'user.checkEmailResponse',
  logoutUser = 'user.logout',
  addToWaitListRequest = 'user.addToWaitListRequest',
  addToWaitListResponse = 'user.addToWaitListResponse',
  addToWaitListError = 'user.addToWaitListError',

  placeOrderRequest = 'user.placeOrderRequest',
  placeOrderResponse = 'user.placeOrderResponse',
  placeOrderError = 'user.placeOrderError',

  checkStatusRequest = 'user.checkStatusRequest',
  checkStatusResponse = 'user.checkStatusResponse',
  checkStatusError = 'user.checkStatusError',
  checkStatusNotFound = 'user.checkStatusNotFound',
  checkStatusClear = 'user.checkStatusClear',

  cancelationRequest = 'user.cancelationRequest',
  cancelationResponse = 'user.cancelationResponse',
  cancelationError = 'user.cancelationError',
}

export interface UserCheckEmail {
  readonly type: UserActionTypes.checkEmailResponse;
  readonly payload: User;
}

export interface UserOrderStatus {
  readonly type: UserActionTypes.checkStatusResponse;
  readonly payload: User;
}

export interface UserPlaceOrder {
  readonly type: UserActionTypes.placeOrderResponse;
  readonly payload: UserPurchase;
}

export interface UserCancelation {
  readonly type: UserActionTypes.cancelationResponse;
  readonly payload: string;
}

export interface UserCheckEmailNotFound {
  readonly type: UserActionTypes.checkEmailNotFound;
  readonly payload: string;
}

export type UserActions = UserCheckEmail | UserPlaceOrder | UserCheckEmailNotFound | UserOrderStatus | UserCancelation;

export const goToPage: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (path: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(push(path));
  };
};

export const checkUserEmail: ActionCreator<ThunkAction<Promise<any>, IAppState, null, UserCheckEmail | UserCheckEmailNotFound>> = (email: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().user.isFetchingUser) {
      dispatch({
        type: UserActionTypes.checkEmailRequest,
      });
      try {
        const response = await findUser(email);
        dispatch({
          payload: response.data,
          type: UserActionTypes.checkEmailResponse,
        });
        // if response has email, start session
        if (response.data.email && !sessionKey() && response.data.can_order) {
          startSession(response.data.email, response.data.id);
          //dispatch(push('/home'))
        }
      } catch (err) {
        if (err.response && err.response.data.type === 'not-found') {
          dispatch({
            type: UserActionTypes.checkEmailNotFound,
            payload: email
          });
          await addUser(email, true);
        } else {
          dispatch({
            type: UserActionTypes.checkEmailRequestFailed,
          });
        }
      }
    } else {
      console.log('Already fetching');
    }
  };
};

export const requestOrderCancel: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (reason: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const user = getState().user.user;
    if (user && !getState().user.isRequestingCancelation) {
      dispatch({
        type: UserActionTypes.cancelationRequest,
      });
      try {
        const response = await cancelOrder(user, reason);
        dispatch({
          type: UserActionTypes.cancelationResponse,
          payload: response.data.confirmation
        });
      } catch (err) {
        dispatch({
          type: UserActionTypes.cancelationError,
        });
      }
    } else {
      console.log('Already trying to cancel');
    }
  };
};

export const clearStatusFail: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (getState().user.checkingStatusFailed.length > 0) {
      dispatch({
        type: UserActionTypes.checkStatusClear
      });
    }
  }
};

export const checkOrderStatus: ActionCreator<ThunkAction<Promise<any>, IAppState, null, UserOrderStatus>> = (email: string, orderId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().user.isCheckingStatus) {
      dispatch({
        type: UserActionTypes.checkStatusRequest,
      });
      try {
        const response = await fetchOrderStatus(email, orderId);
        dispatch({
          payload: response.data,
          type: UserActionTypes.checkStatusResponse,
        });
        dispatch(push('/status'));
      } catch (err) {
        if (err.response && err.response.data.type === 'not-found') {
          dispatch({
            type: UserActionTypes.checkStatusNotFound
          });
        } else {
          dispatch({
            type: UserActionTypes.checkStatusError,
          });
        }
      }
    } else {
      console.log('Already fetching');
    }
  };
};

export const addToWaitList: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (email: string, sendEmail: boolean = true) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().user.isAddingToWaitList) {
      dispatch({
        type: UserActionTypes.addToWaitListRequest,
      });
      try {
        await addUser(email, sendEmail);
        dispatch({
          type: UserActionTypes.addToWaitListResponse,
        });
      } catch (err) {
        dispatch({
          type: UserActionTypes.addToWaitListError,
        });
      }
    } else {
      console.log('Already adding');
    }
  };
};

export const placeOrder: ActionCreator<ThunkAction<Promise<any>, IAppState, null, UserPlaceOrder>> = (source: string, user: User) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().user.isPlacingOrder && user !== undefined) {
      dispatch({
        type: UserActionTypes.placeOrderRequest,
      });
      try {
        const response = await purchase(user, source);
        logEvent('Purchase', {'Amount Spent': '499'});
        setSuperProps({purchased: true});
        dispatch({
          payload: {
            user: user,
            stripe: response.data
          },
          type: UserActionTypes.placeOrderResponse,
        });
        dispatch(push('/success'));
      } catch (err) {
        dispatch({
          type: UserActionTypes.placeOrderError,
        });
      }
    } else {
      console.log('Already fetching');
    }
  };
};

export const logoutUser: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (email: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (getState().user) {
      endSession();
      dispatch({
        type: UserActionTypes.logoutUser,
      });
    }
  };
};
