import amplitude from 'amplitude-js';
const AMPLITUDE_KEY = '27d0ec1a4f7b99959dc649e0f533036c';

amplitude.init(AMPLITUDE_KEY, undefined, {
  includeReferrer: true,
  includeUtm: true
}, () => { console.info('amp started') });

export const logEvent = (name: string, props?: {[key: string]: string}) => {
  amplitude.getInstance().logEvent(name, props)
};

export const setSuperProps = (props: {[key: string]: string | boolean | number}) => {
  amplitude.getInstance().setUserProperties(props);
};

export const incrementSuperProp = (name: string) => {
  const identify = new amplitude.Identify().add(name, 1);
  amplitude.getInstance().identify(identify);
};

export const logPageView = (pageName: string) => {
  // check for source
  const source = getUrlParameter('source');
  if (source !== null) {
    setSuperProps({
      'Source': source
    });
  }

  // log page
  console.log('logging page view ' + pageName);
  logEvent('Page View', {
    'page_title': pageName
  });
  incrementSuperProp('total_page_views');
};

export const setUserId = (userId?: string) => {
  amplitude.getInstance().setUserId(userId || null)
};

const getUrlParameter = (name: string) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};