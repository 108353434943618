// Import Reducer type
import {Action, Reducer} from 'redux';
import {GeneralError, User} from "./user";
import {AdminActions, AdminActionTypes} from "../actions/admin";

export interface AdminState {
  readonly users: Array<User>;
  readonly user?: User;
  readonly isFetchingUserList: boolean;
  readonly fetchingUserListFailed: boolean;
  readonly isFindingUser: boolean;
  readonly findingUserFailed: string;
  readonly isAddingUser: boolean;
  readonly addingUserFailed: string;
  readonly isAddingUserToWhiteList: boolean;
  readonly addingUserToWhiteListFailed: boolean;
}

// Define the initial state
const initialCharacterState: AdminState = {
  users: [],
  user: undefined,
  isFetchingUserList: false,
  fetchingUserListFailed: false,
  isFindingUser: false,
  findingUserFailed: '',
  isAddingUser: false,
  addingUserFailed: '',
  isAddingUserToWhiteList: false,
  addingUserToWhiteListFailed: false
};

export const adminReducer: Reducer<AdminState, AdminActions & Action> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case AdminActionTypes.userListResponse: {
      const users = action.payload as Array<User>;
      return {
        ...state,
        users: [
          ...state.users,
          ...users
        ],
        isFetchingUserList: false,
        fetchingUserListFailed: false
      }
    }
    case AdminActionTypes.userListRequest: {
      return {
        ...state,
        isFetchingUserList: true,
        fetchingUserListFailed: false
      }
    }
    case AdminActionTypes.userListError: {
      return {
        ...state,
        isFetchingUserList: false,
        fetchingUserListFailed: true
      }
    }
    case AdminActionTypes.getUserResponse: {
      const user = action.payload as User;
      return {
        ...state,
        user: user,
        isFindingUser: false,
        findingUserFailed: ''
      }
    }
    case AdminActionTypes.getUserRequest: {
      return {
        ...state,
        isFindingUser: true,
        findingUserFailed: ''
      }
    }
    case AdminActionTypes.getUserError: {
      const error = action.payload as GeneralError;
      return {
        ...state,
        isFindingUser: false,
        findingUserFailed: error.type
      }
    }
    case AdminActionTypes.addUserResponse: {
      return {
        ...state,
        isAddingUser: false,
        addingUserFailed: ''
      }
    }
    case AdminActionTypes.addUserRequest: {
      return {
        ...state,
        isAddingUser: true,
        addingUserFailed: ''
      }
    }
    case AdminActionTypes.addUserError: {
      const error = action.payload as GeneralError;
      return {
        ...state,
        isAddingUser: false,
        addingUserFailed: error.type || 'unknown'
      }
    }
    case AdminActionTypes.addToWhiteListResponse: {
      const modUser = Object.assign({}, state.user);
      modUser.can_order = true;
      return {
        ...state,
        user: modUser,
        isAddingUserToWhiteList: false,
        addingUserToWhiteListFailed: false
      }
    }
    case AdminActionTypes.addToWhiteListRequest: {
      return {
        ...state,
        isAddingUserToWhiteList: true,
        addingUserToWhiteListFailed: false
      }
    }
    case AdminActionTypes.addToWhiteListError: {
      return {
        ...state,
        isAddingUserToWhiteList: false,
        addingUserToWhiteListFailed: false
      }
    }
    case AdminActionTypes.clearFoundUser: {
      return {
        ...state,
        user: undefined
      }
    }
    case AdminActionTypes.clearUserAddError: {
      return {
        ...state,
        addingUserFailed: ''
      }
    }
    default:
      return state;
  }
};