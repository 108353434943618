import React, {FormEvent} from 'react';
import styles from './index.module.scss';
import {TimeStamp, User} from "../../reducers/user";
import {logEvent} from "../../services/AnalyticsService";
import PrimaryButton from "../PrimaryButton";
import Modal from "react-modal";
import closeIcon from '../../images/close-modal.svg';

import IcnCheck from '../../images/icn-checkmark.svg';
import IcnShipped from '../../images/icn-shipped.svg';
import IcnCancelled from '../../images/icn-canceled.svg';

interface LocalState {
  readonly reason: string;
  readonly showModal: boolean;
}

interface Props {
  readonly title: string;
  readonly requestOrderCancel: (reason: string) => void;
  readonly user?: User;
  readonly hideCancel?: boolean;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#EEEFF5'
  },
  overlay: {
    backgroundColor: 'rgba(14,18,32,0.95)'
  }
};

class StatusModule extends React.Component<Props, LocalState> {
  state = {
    reason: '',
    showModal: false
  };

  formatDate = (timestamp: TimeStamp) => {
    const date = new Date(timestamp._seconds * 1000);

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  };

  getStatus = () => {
    if (!this.props.user) {
      return '';
    } else if (this.props.user.fulfilled) {
      return 'Shipped';
    } else if (this.props.user.ordered) {
      return 'Ordered';
    } else if (this.props.user.canceled) {
      return 'Canceled';
    }
  };

  getStatusIcon = (): string => {
    if (this.props.user) {
      if (this.props.user.fulfilled) {
        return IcnShipped;
      } else if (this.props.user.ordered) {
        return IcnCheck;
      } else if (this.props.user.canceled) {
        return IcnCancelled;
      }
    }
    return IcnCheck;
  };

  processCancelation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    logEvent('Cancel');
    this.props.requestOrderCancel(this.state.reason);

    this.toggleModal();
  };

  handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (e.currentTarget.value.length > 500) {
      return;
    }
    this.setState({
      reason: e.currentTarget.value
    });
  };

  toggleModal = () => {
    if (!this.state.showModal) {
      //opening
      logEvent('Request To Cancel');
    }
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const isCanceled = this.getStatus() === 'Canceled' ? styles.canceled : '';
    return (
      <div>
        <div className={styles.priceModule}>
          <h2>{this.props.title}</h2>

          <div>
            <div className={styles.breakDownItem}>
              <div>WPC Entry</div>
              <div>included</div>
            </div>
            <div className={styles.breakDownItem}>
              <div>Putter attachment</div>
              <div>included</div>
            </div>
            <div className={styles.breakDownItem}>
              <div>Total</div>
              <div>$499.00</div>
            </div>
          </div>

          {
            this.props.user &&
            <div className={styles.orderDate}>Ordered on {this.formatDate(this.props.user.order_date)}</div>
          }
          <div className={`${styles.details} ${isCanceled}`}>
            <div>Order Number</div>
            <div className={styles.boldDetail}>{this.props.user ? this.props.user.order_id : ''}</div>
            <div>Status: <span className={styles.status}>{this.getStatus()}</span> <img className={styles.statusIcon} src={this.getStatusIcon()} alt="status"/> </div>
          </div>

          {
            this.props.user && !this.props.user.canceled &&
            <div className={styles.cancelation}>
                Need to cancel?
                <br/>
                <button onClick={this.toggleModal}>Request cancelation</button>
            </div>
          }
        </div>

        {
          this.props.user && !this.props.user.canceled &&
          <Modal
              isOpen={this.state.showModal}
              onRequestClose={this.toggleModal}
              style={customStyles}
              contentLabel="Cancelation"
              ariaHideApp={false}
              closeTimeoutMS={300}
          >

              <div className={styles.modal}>
                  <h2>We're sorry to hear you'd like to cancel</h2>
                  <button className={styles.closeModal} onClick={this.toggleModal}><img src={closeIcon} alt="close"/></button>
                {
                  !this.props.user.fulfilled && !this.props.user.canceled && this.props.user.ordered &&
                  <form onSubmit={this.processCancelation}>
                      <textarea placeholder="What went wrong?..." className={styles.reasonInput} name="reason" value={this.state.reason} onChange={this.handleReasonChange}/>
                      <PrimaryButton>Cancel Order</PrimaryButton>
                  </form>
                }
              </div>
          </Modal>
        }
      </div>
    );
  }
}

export default StatusModule;
