import React from 'react';
import styles from './index.module.scss';
import SPInput from "../../SPInput";
import {completeAddress} from "../../../services/AutocompleteService";
import {debounce} from "ts-debounce";
import {isValidState} from "../../../services/Validation";

interface LocalState {
  readonly expanded: boolean;
  readonly line1: string;
  readonly line2: string;
  readonly city: string;
  readonly state: string;
  readonly zipcode: string;
  readonly results: Array<Suggestion>;
  readonly line1Error: string;
  readonly cityError: string;
  readonly stateError: string;
  readonly zipcodeError: string;
}

interface Suggestion {
  readonly city: string;
  readonly state: string;
  readonly street_line: string;
  readonly text: string;
}

interface Props {
  readonly inError: boolean;
  readonly didChange: (line1: string,
                       line2: string,
                       city: string,
                       state: string,
                       zipcode: string) => void;
}

class AddressInput extends React.Component<Props, LocalState> {
  state = {
    expanded: false,
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
    results: [],
    line1Error: '',
    cityError: '',
    stateError: '',
    zipcodeError: ''
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.inError === false && nextProps.inError === true) {
      this.validate();
      this.setState({
        expanded: true
      });
    }

    if (this.props.inError === true && nextProps.inError === false) {
      this.setState({
        line1Error: '',
        cityError: '',
        stateError: '',
        zipcodeError: ''
      });
    }
  }

  validate = () => {
    if (this.state.line1.length === 0) {
      this.setState({
        line1Error: 'Address is required'
      });
    }

    if (this.state.city.length === 0) {
      this.setState({
        cityError: 'City is required'
      });
    }

    if (this.state.state.length === 0) {
      this.setState({
        stateError: 'State is required'
      });
    } else if (!isValidState(this.state.state)) {
      this.setState({
        stateError: 'State must be provided in the abbreviated format (e.g VA, TX, TN)'
      });
    }

    if (this.state.zipcode.length === 0) {
      this.setState({
        zipcodeError: 'Zipcode is required'
      });
    }
  };

  handleChange = async (name: string, value: string) => {
    if (name === 'line1' && value.length > 0) {
      try {
        let result = await completeAddress(value);
        this.setState({
          results: result.data.suggestions
        })
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        results: []
      });
    }

    let val = value;

    if (name === 'state') {
      val = val.toUpperCase();
    }

    let updatable: any = {};
    updatable[name] = val;
    this.setState(updatable, () => {
      this.props.didChange(
        this.state.line1,
        this.state.line2,
        this.state.city,
        this.state.state,
        this.state.zipcode
      );
    });
  };

  suggestionSelected = (suggestion: Suggestion) => {
    this.setState({
      city: suggestion.city,
      state: suggestion.state,
      line1: suggestion.street_line,
      expanded: true,
      results: []
    }, () => {
      const zip = document.getElementById('zipcode');
      if (zip !== null) {
        zip.focus();
      }
    });
  };

  suggestionsList = () => {
    return this.state.results.map((suggestion: Suggestion, index: number) => {
      return (
        <div key={index} className={styles.typeOption} onClick={() => this.suggestionSelected(suggestion)}>
          {suggestion.text}
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div className={styles.addressTypeahead}>
          <SPInput label="Mailing Address"
                   name="line1"
                   value={this.state.line1}
                   placeholder="185 Berry St"
                   error={this.state.line1Error}
                   onChange={debounce(this.handleChange, 300)}/>
          {
            this.state.results && this.state.results.length > 0 &&
            <div className={`${styles.typeaheadOptions} ${styles.animated} ${styles.fadeInUp} ${styles.faster}`}>
              {this.suggestionsList()}
            </div>
          }
        </div>
        {
          this.state.expanded &&
            <div>
                <SPInput label="Address Line 2"
                         name="line2"
                         placeholder="Apt, Suite, Floor..."
                         value={this.state.line2}
                         onChange={this.handleChange}/>
                <SPInput label="City"
                         name="city"
                         placeholder="Austin"
                         value={this.state.city}
                         error={this.state.cityError}
                         onChange={this.handleChange}/>
                <SPInput label="State"
                         name="state"
                         placeholder="TX"
                         value={this.state.state}
                         error={this.state.stateError}
                         onChange={this.handleChange}/>
            </div>
        }
        <SPInput label="Zipcode"
                 name="zipcode"
                 placeholder="00000"
                 error={this.state.zipcodeError}
                 onChange={this.handleChange}/>
      </div>
    );
  }
}

export default AddressInput;