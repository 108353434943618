// Import redux types
import {ActionCreator, AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {addUser, findUser, getAll, updateUser} from '../services/UserService';

// Import Character Typing
import {GeneralError, User} from '../reducers/user';
import {IAppState} from "../store/Store";

// Create Action Constants
export enum AdminActionTypes {
  userListRequest = 'admin.userListRequest',
  userListResponse = 'admin.userListResponse',
  userListError = 'admin.userListError',

  getUserRequest = 'admin.getUserRequest',
  getUserResponse = 'admin.getUserResponse',
  getUserError = 'admin.getUserError',
  clearFoundUser = 'admin.clearFoundUser',

  addUserRequest = 'admin.addUserRequest',
  addUserResponse = 'admin.addUserResponse',
  addUserError = 'admin.addUserError',
  clearUserAddError = 'admin.clearUserAddError',

  addToWhiteListRequest = 'admin.addToWhiteListRequest',
  addToWhiteListResponse = 'admin.addToWhiteListResponse',
  addToWhiteListError = 'admin.addToWhiteListError',
}

export interface UserList {
  readonly type: AdminActionTypes.userListResponse;
  readonly payload: Array<User>;
}


export interface FindUser {
  readonly type: AdminActionTypes.getUserResponse;
  readonly payload: User;
}

export interface FindUserError {
  readonly type: AdminActionTypes.getUserResponse;
  readonly payload: GeneralError;
}

export type AdminActions = UserList | FindUser | FindUserError;

export const getUserList: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (email: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().admin.isFetchingUserList) {
      dispatch({
        type: AdminActionTypes.userListRequest,
      });
      try {
        const response = await getAll();
        dispatch({
          type: AdminActionTypes.userListResponse,
          payload: response.data.accounts
        });
      } catch (err) {
        dispatch({
          type: AdminActionTypes.userListError,
        });
      }
    } else {
      console.log('Already fetching');
    }
  };
};

export const clearFoundUser: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (getState().admin.user !== undefined) {
      dispatch({
        type: AdminActionTypes.clearFoundUser,
      });
    }
  };
};

export const clearUserAddError: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (getState().admin.addingUserFailed && getState().admin.addingUserFailed.length > 0) {
      dispatch({
        type: AdminActionTypes.clearUserAddError,
      });
    }
  };
};

export const findUserByEmail: ActionCreator<ThunkAction<Promise<any>, IAppState, null, FindUser>> = (email: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().admin.isFindingUser) {
      dispatch({
        type: AdminActionTypes.getUserRequest,
      });
      try {
        const response = await findUser(email, true);
        dispatch({
          payload: response.data,
          type: AdminActionTypes.getUserResponse,
        });
      } catch (err) {
        dispatch({
          type: AdminActionTypes.getUserError,
          payload: err.response.data
        });
      }
    } else {
      console.log('Already fetching');
    }
  };
};

export const addOneUser: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (email: string, created: () => void) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().admin.isAddingUser) {
      dispatch({
        type: AdminActionTypes.addUserRequest,
      });
      try {
        await addUser(email, false);
        dispatch({
          type: AdminActionTypes.addUserResponse,
        });
        created();
      } catch (err) {
        dispatch({
          type: AdminActionTypes.addUserError,
          payload: err.response.data
        });
      }
    } else {
      console.log('Already adding');
    }
  };
};


export const moveToWhiteList: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (user: User, added: () => void) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().admin.isAddingUserToWhiteList) {
      dispatch({
        type: AdminActionTypes.addToWhiteListRequest,
      });
      try {
        await updateUser(user, true);
        dispatch({
          type: AdminActionTypes.addToWhiteListResponse,
        });
        added();
      } catch (err) {
        dispatch({
          type: AdminActionTypes.addToWhiteListError
        });
      }
    } else {
      console.log('Already adding');
    }
  };
};
