import React from 'react';
import styles from './index.module.scss';

const SecondaryButton: React.FC<{onClick?: () => void, disabled?: boolean}> = ({children, onClick, disabled}) => {
  const disabledClass = disabled ? styles.disabled : '';
  return (
    <button formAction="submit" onClick={!disabled ? onClick : () => {}} className={`${styles.main} ${disabledClass}`}>
      {children}
    </button>
  )
};

export default SecondaryButton;
