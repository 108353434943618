import React from 'react';
import styles from './index.module.scss';
import {Link} from "react-router-dom";

const PrimaryButton: React.FC<{onClick?: () => void, disabled?: boolean, to?: string}> = ({children, onClick, disabled, to}) => {
  const disabledClass = disabled ? styles.disabled : '';

  if (to) {
    return (
      <Link to={to} onClick={!disabled ? onClick : () => {}} className={`${styles.main} ${disabledClass}`}>
        {children}
      </Link>
    )
  }

  return (
    <button formAction="submit" onClick={!disabled ? onClick : () => {}} className={`${styles.main} ${disabledClass}`}>
      {children}
    </button>
  )
};

export default PrimaryButton;
