// Import Reducer type
import {Action, Reducer} from 'redux';
import {UserActions, UserActionTypes,} from '../actions/user';

// Define the Character type
export interface User {
  readonly fulfilled: boolean;
  readonly email: string;
  readonly created: TimeStamp;
  readonly order_date: TimeStamp;
  readonly id: string;
  readonly last_seen: TimeStamp;
  readonly re_engaged_count: number;
  canceled: boolean;
  can_order: boolean;
  name: string;
  phone: string;
  address: UserAddress;
  ordered: boolean;
  order_id: string;
  refund_id: string;
}

export interface UserAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
  readonly country: string;
}

export interface TimeStamp {
  readonly _seconds: number;
}

// Define the Character State
export interface UserState {
  readonly user?: User;
  readonly isFetchingUser: boolean;
  readonly fetchingUserFailed: boolean;
  readonly wasAddedToWaitList: boolean;
  readonly isAddingToWaitList: boolean;
  readonly addingToWaitListFailed: boolean;
  readonly isPlacingOrder: boolean;
  readonly placingOrderFailed: boolean;
  readonly notFoundEmail: string;
  readonly isCheckingStatus: boolean;
  readonly checkingStatusFailed: string;
  readonly isRequestingCancelation: boolean;
  readonly cancelationFailed: string;
}

export interface GeneralError {
  readonly verbose: string;
  readonly type: string;
  readonly field: string;
}

export interface UserPurchase {
  readonly user: User;
  readonly stripe: {order_id: string};
}

// Define the initial state
const initialCharacterState: UserState = {
  user: undefined,
  isFetchingUser: false,
  fetchingUserFailed: false,
  wasAddedToWaitList: false,
  isAddingToWaitList: false,
  addingToWaitListFailed: false,
  isPlacingOrder: false,
  placingOrderFailed: false,
  notFoundEmail: '',
  isCheckingStatus: false,
  checkingStatusFailed: '',
  isRequestingCancelation: false,
  cancelationFailed: ''
};

export const userReducer: Reducer<UserState, UserActions & Action> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.checkEmailResponse: {
      return {
        ...state,
        user: action.payload as User,
        notFoundEmail: '',
        isFetchingUser: false,
        fetchingUserFailed: false,
      };
    }
    case UserActionTypes.checkEmailRequestFailed: {
      return {
        ...state,
        notFoundEmail: '',
        isFetchingUser: false,
        fetchingUserFailed: true,
      };
    }
    case UserActionTypes.checkEmailNotFound: {
      const notFoundEmail = action.payload as string;
      return {
        ...state,
        notFoundEmail: notFoundEmail,
        isFetchingUser: false,
        fetchingUserFailed: false,
      }
    }
    case UserActionTypes.checkEmailRequest: {
      return {
        ...state,
        notFoundEmail: '',
        isFetchingUser: true,
        fetchingUserFailed: false,
      };
    }
    case UserActionTypes.checkStatusResponse: {
      return {
        ...state,
        user: action.payload as User,
        isCheckingStatus: false,
        checkingStatusFailed: '',
      };
    }
    case UserActionTypes.checkStatusError: {
      return {
        ...state,
        notFoundEmail: '',
        isCheckingStatus: false,
        checkingStatusFailed: 'unknown',
      };
    }
    case UserActionTypes.checkStatusNotFound: {
      return {
        ...state,
        isCheckingStatus: false,
        checkingStatusFailed: 'not-found',
      }
    }
    case UserActionTypes.checkStatusClear: {
      return {
        ...state,
        checkingStatusFailed: '',
      }
    }
    case UserActionTypes.checkStatusRequest: {
      return {
        ...state,
        isCheckingStatus: true,
        checkingStatusFailed: '',
      };
    }
    case UserActionTypes.addToWaitListResponse: {
      return {
        ...state,
        wasAddedToWaitList: true,
        isAddingToWaitList: false,
        addingToWaitListFailed: false
      }
    }
    case UserActionTypes.addToWaitListRequest: {
      return {
        ...state,
        isAddingToWaitList: true,
        addingToWaitListFailed: false
      }
    }
    case UserActionTypes.addToWaitListError: {
      return {
        ...state,
        isAddingToWaitList: false,
        addingToWaitListFailed: true
      }
    }
    case UserActionTypes.cancelationResponse: {
      const refundConfirmation = action.payload as string;
      let mutableUser = Object.assign({}, state.user);
      mutableUser.ordered = false;
      mutableUser.refund_id = refundConfirmation;
      mutableUser.canceled = true;
      return {
        ...state,
        user: mutableUser,
        isRequestingCancelation: false,
        cancelationFailed: ''
      }
    }
    case UserActionTypes.cancelationRequest: {
      return {
        ...state,
        isRequestingCancelation: true,
        cancelationFailed: ''
      }
    }
    case UserActionTypes.cancelationError: {
      return {
        ...state,
        isRequestingCancelation: false,
        cancelationFailed: 'unknown'
      }
    }
    case UserActionTypes.placeOrderResponse: {
      const orderPayload = action.payload as UserPurchase;
      const newUser = orderPayload.user;
      let mutableUser = Object.assign({}, state.user);
      mutableUser.order_id = orderPayload.stripe.order_id;
      mutableUser.ordered = true;
      mutableUser.canceled = false;
      mutableUser.name = newUser.name;
      mutableUser.address = newUser.address;
      return {
        ...state,
        user: mutableUser,
        isPlacingOrder: false,
        placingOrderFailed: false
      }
    }
    case UserActionTypes.placeOrderRequest: {
      return {
        ...state,
        isPlacingOrder: true,
        placingOrderFailed: false
      }
    }
    case UserActionTypes.placeOrderError: {
      return {
        ...state,
        isPlacingOrder: false,
        placingOrderFailed: true
      }
    }
    case UserActionTypes.logoutUser: {
      return {
        ...state,
        user: undefined
      }
    }
    default:
      return state;
  }
};