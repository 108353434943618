import React, {FormEvent} from 'react';
import styles from './index.module.scss';
import {User} from "../../reducers/user";
import PrimaryButton from "../PrimaryButton";
import Modal from "react-modal";
import closeIcon from '../../images/close-modal.svg';
import SPInput from "../SPInput";
import {incrementSuperProp, logEvent} from "../../services/AnalyticsService";
import {isValidEmail} from "../../services/Validation";

interface LocalState {
  readonly emailError: string;
  readonly orderIdError: string;
  readonly email: string;
  readonly orderId: string;
}

interface Props {
  readonly user?: User;
  readonly show: boolean;
  readonly statusCheckFail: string;
  readonly close: () => void;
  readonly checkOrderStatus: (email: string, orderId: string) => void;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: 500,
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#EEEFF5'
  },
  overlay: {
    backgroundColor: 'rgba(14,18,32,0.95)',
    zIndex: 1000
  }
};

class OrderStatusModal extends React.Component<Props, LocalState> {
  state = {
    orderId: '',
    email: '',
    emailError: '',
    orderIdError: ''
  };

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.user && nextProps.user) {
      this.setState({
        email: nextProps.user ? nextProps.user.email : ''
      });
    }
  }

  inputChange = (name: string, value: string) => {
    if (name === 'order') {
      this.setState({
        orderId: value,
        emailError: '',
        orderIdError: ''
      });
    } else {
      this.setState({
        email: value,
        emailError: '',
        orderIdError: ''
      });
    }
  };

  submitDisabled = () => {
    return (this.state.email.length === 0 || !isValidEmail(this.state.email) || this.state.orderId.length === 0)
  };

  statusCheckError = () => {
    if (this.props.statusCheckFail === 'not-found') {
      return 'The order id does not match the provided email.';
    } else if (this.props.statusCheckFail.length > 0) {
      return 'We\'re not sure what went wrong but we\'re looking into it!';
    }
    return '';
  };

  submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let valid = true;

    if (this.state.email.length === 0) {
      this.setState({
        emailError: 'Must provide an email'
      });
      valid = false;
    } else if (!isValidEmail(this.state.email)) {
      this.setState({
        emailError: 'Not a valid email'
      });
      valid = false;
    }

    if (this.state.orderId.length === 0) {
      this.setState({
        orderIdError: 'Must provide an order id'
      });
      valid = false;
    }

    if (valid) {
      logEvent('Check Order Status');
      incrementSuperProp('Number of Check Ins');
      this.props.checkOrderStatus(this.state.email, this.state.orderId);
    }
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          onRequestClose={this.props.close}
          style={customStyles}
          contentLabel="Check Order Status"
          ariaHideApp={false}
          closeTimeoutMS={300}
        >
          <button className={styles.closeModal} onClick={this.props.close}><img src={closeIcon} alt="close"/></button>
          <div className={styles.modal}>
            <h2>Check Order Status</h2>

            <form onSubmit={this.submit}>
              <SPInput label="Email"
                       value={this.state.email}
                       error={this.state.emailError}
                       name="email"
                       placeholder="example@gmail.com"
                       onChange={this.inputChange}/>
              <SPInput label="Order Number"
                       name="order"
                       error={this.state.orderIdError.length === 0 ? this.statusCheckError() : this.state.orderIdError}
                       placeholder="Enter 24-digit order number"
                       onChange={this.inputChange}/>
              <PrimaryButton disabled={this.submitDisabled()}>Check Status</PrimaryButton>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderStatusModal;
