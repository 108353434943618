import React, {FormEvent} from 'react';
import styles from './index.module.scss';
import {User} from '../../reducers/user';
import {injectStripe, ReactStripeElements} from 'react-stripe-elements';
import CardInput from "./CardInput";
import AddressInput from "./AddressInput";
import {logEvent} from "../../services/AnalyticsService";
import SPInput from "../SPInput";
import PrimaryButton from "../PrimaryButton";
import {isValidState} from "../../services/Validation";

interface LocalState {
  readonly name: string;
  readonly phone: string;
  readonly line1: string;
  readonly line2: string;
  readonly city: string;
  readonly state: string;
  readonly zipcode: string;
  readonly nameError: string;
  readonly addressInError: boolean;
  readonly cardFilled: boolean;
  readonly cardInError: boolean;
}

interface Props {
  readonly placeOrder: (source: string, user: User) => void;
  readonly user?: User;
  readonly stripe?: any;
}

class CheckoutForm extends React.Component<Props, LocalState> {
  state = {
    name: '',
    phone: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
    nameError: '',
    addressInError: false,
    cardFilled: false,
    cardInError: false
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasError = false;
    if (this.props.user === undefined) { return }

    if (this.state.name.length === 0) {
      this.setState({
        nameError: 'Name is required'
      });
      hasError = true;
    }

    if (this.state.line1.length === 0
      || this.state.city.length === 0
      || this.state.state.length === 0
      || this.state.zipcode.length === 0
      || !isValidState(this.state.state)) {
      this.setState({
        addressInError: true
      });
      hasError = true;
    }

    if (!this.state.cardFilled) {
      this.setState({
        cardInError: true
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      const {source, error} = await this.props.stripe.createSource({
        type: 'card',
        owner: {
          email: this.props.user.email,
          name: this.state.name
        },
      });
      if (error) {
        console.log(error);
        return;
      }
      this.placeOrder(source.id);
    } catch(err) {
      console.log(err);
    }
  };

  placeOrder = (source: string) => {
    const user = this.props.user;
    if (user === undefined) {
      return;
    }
    user.name = this.state.name;
    user.phone = this.state.phone;
    if (!user.address) {
      user.address = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'US'
      }
    }
    user.address.line1 = this.state.line1;
    user.address.line2 = this.state.line2;
    user.address.city = this.state.city;
    user.address.state = this.state.state;
    user.address.zipcode = this.state.zipcode;

    this.props.placeOrder(source, user);
  };

  handleChange = (name: string, value: string) => {
    if (name === 'name') {
      this.setState({
        name: value,
        nameError: ''
      });
    } else if (name === 'phone') {
      this.setState({
        phone: value
      });
    }
  };

  addressChanged = (line1: string,
                    line2: string,
                    city: string,
                    state: string,
                    zipcode: string) => {
    this.setState({
      line1,
      line2,
      city,
      state,
      zipcode,
      addressInError: false
    })
  };

  onCardChange = (event: ReactStripeElements.ElementChangeResponse) => {
    this.setState({
      cardFilled: event.complete
    });
  };

  render() {
    return (
      <div className={styles.main}>
        <form onSubmit={this.handleSubmit}>
          <SPInput label="Name"
                   name="name"
                   placeholder="Full Name"
                   error={this.state.nameError}
                   onChange={this.handleChange}/>
          <div className={styles.halfInput}>
            <SPInput label="Email"
                     name="email"
                     readOnly
                     value={this.props.user ? this.props.user.email : ''}
                     placeholder="Email" onChange={this.handleChange}/>
            <SPInput label="Phone (optional)"
                     name="phone"
                     type="phone"
                     placeholder="(555) 555 - 5555"
                     onChange={this.handleChange}/>
          </div>

          <AddressInput didChange={this.addressChanged} inError={this.state.addressInError}/>
          <CardInput cardInError={this.state.cardInError} onChange={this.onCardChange}/>
          <div className={styles.pay}>
            <PrimaryButton>Pay</PrimaryButton>
          </div>
        </form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
